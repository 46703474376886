import React from 'react';
import { OfferToolTip } from '../../helpers';
import ErrorIcon from '@mui/icons-material/Error';
import { usePO } from '../../utils/POContext';

interface Props {
	formErrorMessage: string;
}

const FormErrorIcon: React.FC<Props> = ({ formErrorMessage }: Props) => {
	const { selectedTheme } = usePO();
	return formErrorMessage ? (
		<OfferToolTip
			title={`${formErrorMessage}`}
			color={selectedTheme.error}
			backgroundColor={
				selectedTheme.id === 'dark' ? selectedTheme?.overlay4dp : '#eaeaeaf7'
			}
			border={selectedTheme.id === 'dark' ? `1px solid #575757` : undefined}
			arrow
		>
			<ErrorIcon sx={{ color: selectedTheme.error, fontSize: '18px' }} />
		</OfferToolTip>
	) : null;
};

export default FormErrorIcon;
