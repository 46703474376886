import React from 'react';
import { Popover } from '@mui/material';
import { Column } from '../../../styles';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';
import { usePO } from '../../../utils/POContext';

const CategoriesPopover = ({
	anchorEl,
	open,
	onClose,
	routeData = { title: '', route: [] },
}) => {
	const { selectedTheme } = usePO();

	const renderTreeItems = (items) => {
		return items.map((item) => (
			<TreeItem
				key={item.id}
				itemId={item.id}
				label={item.name}
				sx={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			/>
		));
	};

	return (
		<Popover
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			slotProps={{
				paper: {
					style: {
						padding: '10px',
						boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
						borderRadius: '4px',
						backgroundColor:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay4dp
								: selectedTheme.foreground,
					},
				},
			}}
		>
			<Column>
				<SimpleTreeView
					defaultExpandedItems={['parent']}
					disableSelection
					sx={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					<TreeItem itemId="parent" label={routeData.title || 'N/A'}>
						{routeData.route && renderTreeItems(routeData.route)}
					</TreeItem>
				</SimpleTreeView>
			</Column>
		</Popover>
	);
};

export default CategoriesPopover;
