import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

interface UpdateResult {
	success: boolean;
	status?: number;
	data?: any;
	message?: string;
}

export const updateAuthorOfficial = async (
	authorIDs: string[],
	officialStatus: boolean
): Promise<UpdateResult[]> => {
	const results: UpdateResult[] = [];
	for (const authorID of authorIDs) {
		try {
			const response = await api.patch(`/authors/${authorID}/official`, null, {
				params: { official: officialStatus },
			});
			results.push({
				success: true,
				status: response.status,
				data: response.data,
				message: response.data.detail,
			});
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					results.push({
						success: false,
						status: errorResponse.status,
						message: errorResponse.data.detail,
					});
					continue;
				}
			}
			results.push({
				success: false,
				status: 500,
				message:
					'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
			});
		}
	}
	return results;
};

export const updateAuthorInvestigation = async (
	authorIDs: string[],
	investigationStatus: boolean
): Promise<UpdateResult[]> => {
	const results: UpdateResult[] = [];
	for (const authorID of authorIDs) {
		try {
			const response = await api.patch(
				`/authors/${authorID}/under_investigation`,
				null,
				{
					params: { underInvestigation: investigationStatus },
				}
			);
			results.push({
				success: true,
				status: response.status,
				data: response.data,
				message: response.data.detail,
			});
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					results.push({
						success: false,
						status: errorResponse.status,
						message: errorResponse.data.detail,
					});
					continue;
				}
			}
			results.push({
				success: false,
				status: 500,
				message:
					'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
			});
		}
	}
	return results;
};
