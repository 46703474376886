import React, { useState } from 'react';
import {
	Typography,
	Dialog,
	DialogTitle,
	Zoom,
	IconButton,
	Box,
	Button,
	Grid,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material/';
import convertCountryName from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import { api, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';
import { Line, OfferCard } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { OfferToolTip } from '../../../../helpers';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import { editOfferClassification } from '../../../../services/notifications';
import { enqueueSnackbar } from 'notistack';
import Loading from '../../../../components/LoadingDots';

convertCountryName.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/pt.json')
);

interface Props {
	rowData?: any;
	item?: any;
	images: any;
	open: boolean;
	setOpen: (state: boolean) => void;
	handleReload: () => void;
}

const NotificationModal: React.FC<Props> = ({
	rowData,
	item,
	images,
	open,
	setOpen,
	handleReload,
}: Props) => {
	const { token } = useAuth0();
	const { selectedTheme, selectedClient } = usePO();
	setupRequestToken(api, token);
	const { t } = useTranslation();

	const [denounceValue, setDenounceValue] = useState<any>();
	const [denounceObs, setDenounceObs] = useState(
		rowData?.denouncementObservation || ''
	);

	const [loading, setLoading] = useState(false);

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			setDenounceValue({ id: 'NOT_SET' });
			setDenounceObs('');
		}, 300);
	};

	const handleSaveData = async () => {
		setLoading(true);
		if (!denounceValue?.id) {
			setLoading(false);
			return;
		}
		const payload = {
			offerClassificationID: [
				rowData.offerClassification.offerClassificationID,
			],
			classificationTypeID: denounceValue.id,
			denounceStatusID: 'CLASSIFICADA',
			clientID: selectedClient.id,
			observation: denounceObs,
		};
		try {
			const response: any = await editOfferClassification(payload);
			if (response.status < 300) {
				enqueueSnackbar(t(response.data[0].detail), {
					variant: 'success',
				});
				handleClose();
				handleReload();
			} else {
				enqueueSnackbar(
					t(
						response.data.detail ||
							t('Erro ao editar a denúncia. Tente novamente mais tarde.')
					),
					{
						variant: 'error',
					}
				);
				setDenounceValue(rowData.classificationType.classificationTypeID);
			}
		} catch (error) {
			enqueueSnackbar(
				t('Erro ao editar a denúncia. Tente novamente mais tarde.'),
				{
					variant: 'error',
				}
			);
		}

		setLoading(false);
	};

	const renderHeader = () => (
		<DialogTitle
			style={{
				height: 55,
				paddingInline: 18,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.tableHead
						: selectedTheme.gradient,
				color: '#FFFFFF',
				display: 'flex',
			}}
			id="draggable-dialog-title"
		>
			<Line
				style={{
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<Typography
					noWrap
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						fontSize: 18,
						fontWeight: 'bold',
						maxWidth: '90%',
						marginTop: 2,
					}}
				>
					{item?.title}
				</Typography>
				<IconButton
					onClick={handleClose}
					style={{ marginRight: '-14px' }}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderContent = () => (
		<OfferCard
			style={{
				padding: '15px 20px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.lightBackground,
			}}
		>
			<Grid container spacing={2}>
				<LeftColumn rowData={rowData} item={item} images={images} />
				<RightColumn
					rowData={rowData}
					denounceValue={denounceValue}
					setDenounceValue={setDenounceValue}
					denounceObs={denounceObs}
					setDenounceObs={setDenounceObs}
					item={item}
				/>
			</Grid>
		</OfferCard>
	);

	const renderFooter = () => (
		<Line
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark'
						? selectedTheme.footerLine
						: selectedTheme.disabled,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme?.foreground,
				width: '100%',
				justifyContent: 'flex-start',
				height: 55,
				padding: '5px 12px 5px 8px',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<Line style={{ justifyContent: 'end' }}>
					<Typography
						style={{
							paddingLeft: 10,
							marginRight: 10,
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Buscas.Tabs.Buscas Salvas.Cliente')}:
					</Typography>
					<Box
						width="53%"
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
						display={'flex'}
						justifyContent={'flex-start'}
					>
						<OfferToolTip
							title={selectedClient?.name}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							arrow
							TransitionComponent={Zoom}
						>
							<Typography
								data-testid={selectedClient?.name}
								noWrap
								style={{
									fontWeight: 'bold',
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{selectedClient?.name}
							</Typography>
						</OfferToolTip>
					</Box>
				</Line>
				{loading ? (
					<Loading width={90} height={35} loop />
				) : (
					<Button
						variant="contained"
						onClick={handleSaveData}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: handleBorderButton(),
						}}
					>
						{t('Salvar')}
					</Button>
				)}
			</Line>
		</Line>
	);

	return (
		<Dialog
			open={open}
			scroll="body"
			onClose={(_, reason) => {
				if (reason !== 'escapeKeyDown') {
					handleClose();
				}
			}}
			fullScreen
			PaperProps={{
				style: { background: 'rgba(0,0,0,0.3)' },
			}}
		>
			<div
				style={{
					padding: '1vh 4vw',
					background: 'rgba(0,0,0,0.3)',
				}}
			>
				{renderHeader()}
				{renderContent()}
				{renderFooter()}
			</div>
		</Dialog>
	);
};

export default NotificationModal;
