import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
	Zoom,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { api, setupRequestToken } from '../../../../utils/api';
import Loading from '../../../../components/LoadingDots';
import {
	deactivateClient,
	updateClientStatus,
} from '../../../../services/client';
import { useAuth0 } from '../../../../react-auth0-spa';
import { Line } from '../../../../styles';
import { useSnackbar } from 'notistack';
import { OfferSwitch, OfferToolTip } from '../../../../helpers';
import { usePO } from '../../../../utils/POContext';
import { apiResponse } from '../../../../interfaces';
import { ClientType } from '../../../../interfaces/Clients';

interface props {
	rowData: ClientType;
	refresh: () => void;
}

const ClientStatusChangeDialog: React.FC<props> = ({
	rowData,
	refresh,
}: props) => {
	const { token } = useAuth0();
	const { selectedTheme } = usePO();
	setupRequestToken(api, token);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [switchState, setSwitchState] = useState(rowData.ISactive);
	const [clientSwitchState, setClientSwitchState] = useState(switchState);

	const switchOfferStyles = (switchState) => {
		if (switchState) {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorHigh
				: selectedTheme.primary;
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.primaryDark
				: selectedTheme.disabled;
		}
	};

	const handlePanelsSwitchChange = (event) => {
		setClientSwitchState(event.target.checked);

		setOpen(true);
	};

	const handleClose = () => {
		setClientSwitchState(switchState);
		setOpen(false);
	};

	const handleClientStatusChange = async () => {
		const payload: ClientType = {
			id: rowData.id,
			name: rowData.name,
			description: rowData.description,
			logo: '',
			ISactive: true,
			created: rowData.created,
			referenceCode: rowData.referenceCode,
			clientID: '',
			BIID: '',
		};
		setLoading(true);
		let response: apiResponse | undefined;
		if (!rowData?.ISactive === false) {
			response = await deactivateClient(rowData.id);
			if (response && response.status) {
				enqueueSnackbar(t('Clientes.Cliente desativado com sucesso'), {
					variant: 'warning',
				});
			}
		} else {
			response = await updateClientStatus(rowData.id, payload);
			if (response && response.status) {
				enqueueSnackbar(t('Clientes.Cliente ativado com sucesso'), {
					variant: 'success',
				});
			}
		}
		setSwitchState(clientSwitchState);
		handleClose();
		setLoading(false);
		refresh();
	};

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: '5px 10px',
				}}
			>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Clientes.Cancelar')}
				</Button>
				{loading ? (
					<Loading width={100} height={30} loop />
				) : (
					<Button
						onClick={handleClientStatusChange}
						style={{
							color:
								selectedTheme?.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					>
						{switchState ? t('Clientes.Desativar') : t('Clientes.Ativar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<div>
			<div style={{ marginLeft: 10 }}>
				<OfferToolTip
					title={
						switchState
							? `${t('Clientes.Desativar o cliente')}`
							: `${t('Clientes.Ativar o cliente')}`
					}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<OfferSwitch
						size="small"
						checked={switchState}
						onChange={handlePanelsSwitchChange}
						style={{
							color: switchOfferStyles(clientSwitchState),
						}}
					/>
				</OfferToolTip>
			</div>

			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
			>
				<DialogContent
					style={{
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					}}
				>
					<Line
						style={{
							justifyContent: 'space-between',
							padding: '2px 10px 10px 10px',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						}}
					>
						<Typography data-testid="textDisable" variant="h6">
							{`${
								switchState
									? t('Clientes.Desativar o cliente')
									: t('Clientes.Ativar o cliente')
							}:  `}
						</Typography>

						<Typography variant="h6" style={{ marginLeft: 5 }}>
							{`${rowData.name}`}
						</Typography>
						<Typography variant="h6">?</Typography>
					</Line>
				</DialogContent>
				{renderFooter()}
			</Dialog>
		</div>
	);
};

export default ClientStatusChangeDialog;
