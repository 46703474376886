import { Cached } from '@mui/icons-material';
import { Zoom, IconButton, Box, Chip, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import OfferTable from '../../../components/OfferTable';
import { OfferToolTip } from '../../../helpers';
import { usePO } from '../../../utils/POContext';
import LogsChart from './LogsCharts';
import MonitoringToggleDialog from './MonitoringToggleDialog';
import { enqueueSnackbar } from 'notistack';
import { getDomains } from '../../../services/domains';
import { Line } from '../../../styles';
import SelectActiveClient from '../../../components/SelectClient';
import { TableData, Item } from '../../../interfaces/Domains';

const Monitoring: React.FC = () => {
	const { selectedTheme, selectedClient } = usePO();

	const [tableData, setTableData] = useState<TableData[]>([]);

	const [refreshTable, setRefreshTable] = useState(0);
	const [loading, setLoading] = useState(false);

	const [openToggleDialog, setOpenToggleDialog] = useState(false);

	const [receivedDataSwitch, setReceivedDataSwitch] = useState<Item | null>(
		null
	);

	const handleRefreshTable = async () => {
		setRefreshTable(refreshTable + 1);
	};

	const handleOpenToggleCategoriesDialog = () => {
		setOpenToggleDialog(true);
	};

	useEffect(() => {
		fetchData();
	}, [selectedClient, refreshTable]);

	const renderFilter = () => (
		<Line>
			<Typography
				style={{
					margin: '0 5px 0 20px',
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			>
				{t('Buscas.Tabs.Monitoramento.Cliente')}:
			</Typography>
			<SelectActiveClient width="200px" />
		</Line>
	);

	const renderStatus = (status) => {
		const tooltipMessage =
			status === 'Online'
				? t('Buscas.Tabs.Monitoramento.Domínio ativo no momento')
				: t('Buscas.Tabs.Monitoramento.Domínio desativado no momento');

		return (
			<OfferToolTip title={tooltipMessage} placement="bottom" arrow>
				<Chip
					label={status}
					variant="outlined"
					icon={
						<Box
							sx={{
								width: 10,
								height: 10,
								borderRadius: '50%',
								backgroundColor: status === 'Online' ? '#5DED55' : '#ED5555',
								border: `1px solid ${status === 'Online' ? '#24891B' : '#891B1B'}`,
								marginLeft: '8px',
							}}
						/>
					}
					sx={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
						fontWeight: 'normal',
						borderRadius: '16px',
						padding: '4px 8px',
						borderColor: selectedTheme.id === 'dark' ? '#585858' : '',
					}}
				/>
			</OfferToolTip>
		);
	};

	const renderReloadTableData = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Monitoramento.Atualizar dados da tabela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={handleRefreshTable}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Cached
					sx={{
						animation: loading ? '$spin 2s linear infinite' : 'normal',
						'@keyframes spin': loading
							? {
									'0%': {
										transform: 'rotate(360deg)',
									},
									'100%': {
										transform: 'rotate(0deg)',
									},
								}
							: null,
						zIndex: !loading ? 2 : 'auto',
					}}
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const switchesToolTip = {
		true: t('Buscas.Tabs.Monitoramento.Monitoramento ativado'),
		false: t('Buscas.Tabs.Monitoramento.Monitoramento desativado'),
	};

	const colsData = [
		{
			columnTitle: t('Buscas.Tabs.Monitoramento.ID da busca'),
			fieldName: 'searchID',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 30,
		},
		{
			columnTitle: t('Buscas.Tabs.Monitoramento.Endereço'),
			fieldName: 'url',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 190,
		},
		{
			columnTitle: t('Buscas.Tabs.Monitoramento.Registros'),
			fieldName: 'logsDisplay',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Buscas.Tabs.Monitoramento.Status'),
			fieldName: 'ISonlineDisplay',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 80,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Buscas.Tabs.Monitoramento.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 90,
			dataType: 'date',
		},
	];

	const fetchData = async () => {
		setLoading(true);
		const response = await getDomains(selectedClient.id);
		if (response?.success === true) {
			buildTableData(response.data);
		} else {
			enqueueSnackbar(t(`Buscas.Tabs.Monitoramento.${response?.message}`), {
				variant: 'error',
			});
		}
	};

	const buildTableData = async (item: Item[]) => {
		if (item) {
			setTableData([]);
			const tempTableData: TableData[] = [];
			await item.map((item) => {
				const hasLogs = item.logs && Object.keys(item.logs).length > 0;
				const formattedData: TableData = {
					id: item.id,
					searchID: item.searchID,
					url: item.url,
					clientID: item.clientID,
					actions: item.actions,
					eventDate: item.eventDate,
					logs: item.logs || {},
					logsDisplay: hasLogs ? <LogsChart logs={item.logs} /> : '-',
					status: item.ISonline,
					ISonlineDisplay: renderStatus(item.ISonline ? 'Online' : 'Offline'),
					created: item.created,
					ISactive: item.ISactive || false,
				};
				tempTableData.push(formattedData);
				return null;
			});
			setTableData(tempTableData);
			setLoading(false);
		}
	};

	return (
		<>
			<OfferTable
				tableData={tableData}
				colsData={colsData}
				tableActions
				tableFooter
				customRightFooterComponent={renderFilter()}
				customLeftFooterComponent={renderReloadTableData()}
				loading={loading}
				customNoDataMessage={
					// eslint-disable-next-line no-nested-ternary
					selectedClient
						? t(
								'Buscas.Tabs.Monitoramento.Sem monitoramentos para este cliente'
							)
						: t('Buscas.Tabs.Monitoramento.Selecione um cliente')
				}
				switches
				switchesToolTip={switchesToolTip}
				dataFromSwitch={setReceivedDataSwitch}
				onSwitchChange={handleOpenToggleCategoriesDialog}
			/>
			<MonitoringToggleDialog
				open={openToggleDialog}
				setOpen={setOpenToggleDialog}
				data={receivedDataSwitch}
				onConfirm={fetchData}
			/>
		</>
	);
};

export default Monitoring;
