import React, { useState, useEffect, useCallback } from 'react';
import OfferTable from '../../components/OfferTable';
import { useTranslation } from 'react-i18next';
import AddCategoriesModal from './AddCategoriesModal';
import { Cached } from '@mui/icons-material';
import { Zoom, IconButton } from '@mui/material';
import { OfferToolTip } from '../../helpers';
import CategoriesPopover from './CategoriesPopover';
import { usePO } from '../../utils/POContext';
import CategoriesToggleDialog from './CategoriesToggleDialog';
import Unauthorized from '../Page401';

const Categories = () => {
	const { t } = useTranslation();
	const { selectedTheme, userData } = usePO();

	const [tableData, setTableData] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [openPopover, setOpenPopover] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [popoverData, setPopoverData] = useState({ title: '', route: [] });
	const [openToggleDialog, setOpenToggleDialog] = useState(false);
	const [receivedDataSwitch, setReceivedDataSwitch] = useState<any>();

	const handlePopoverOpen = (event, routeData, title) => {
		setOpenPopover(true);
		setAnchorEl(event.currentTarget);
		setPopoverData({ route: routeData, title });
	};

	const handlePopoverClose = () => {
		setOpenPopover(!openPopover);
		setAnchorEl(null);
	};

	const handleOpenToggleCategoriesDialog = () => {
		setOpenToggleDialog(true);
	};

	const handleConfirmStatus = (newStatus) => {
		setTableData((prevData) =>
			prevData.map((item) =>
				item.id === receivedDataSwitch.id
					? { ...item, ISactive: newStatus }
					: item
			)
		);
	};

	const switchesToolTip = {
		true: t('Categorias.Categoria ativada'),
		false: t('Categorias.Categoria desativada'),
	};

	const generateRandomData = () => {
		const initialCategories = [
			{
				id: '1',
				title: 'Cachorro',
				description: 'categoria de cachorros',
				route: [
					{ id: '1', name: 'Coleira' },
					{ id: '2', name: 'Ração' },
					{ id: '4', name: 'Bebedouro' },
				],
				parent: '',
				created: '',
			},
			{
				id: '2',
				title: 'Tênis',
				description: 'categoria de Tênis',
				route: [],
				parent: '',
				created: '',
			},
			{
				id: '3',
				title: 'Roupas e acessórios',
				description: 'categoria de roupas e acessórios',
				route: [
					{ id: '6', name: 'Camiseta' },
					{ id: '7', name: 'Calça' },
				],
				parent: '',
				created: '',
			},
		];

		initialCategories.forEach((category, index) => {
			category.created = `2024-01-${String(index + 1).padStart(2, '0')}`;
		});

		initialCategories.forEach((category: any) => {
			const routeItems = category.route
				.filter((routeItem) => routeItem.name)
				.map((routeItem) => routeItem.name);

			category.formattedRoute = (
				<span
					onClick={(event) =>
						handlePopoverOpen(event, category.route, category.title)
					}
					style={{ cursor: 'pointer' }}
				>
					{category.title +
						(routeItems.length > 0 ? ` > ${routeItems.join(', ')}` : '')}
				</span>
			);
		});

		return initialCategories;
	};

	const fetchData = useCallback(() => {
		setLoading(true);
		setTimeout(() => {
			setTableData(generateRandomData());
			setLoading(false);
		}, 1000);
	}, [generateRandomData]);

	useEffect(() => {
		fetchData();
	}, []);

	const handleAddCategory = (newCategory) => {
		const newCategoryWithId = {
			...newCategory,
			id: tableData.length + 1,
			route: newCategory.parent ? [...newCategory.formattedRoute] : [],
			created: new Date().toISOString().split('T')[0],
			formattedRoute: newCategory.parent
				? `${newCategory.parent} > ${newCategory.title}`
				: newCategory.title,
		};

		setTableData((prevData) => [...prevData, newCategoryWithId]);
	};

	const colsData = [
		{
			columnTitle: t('ID'),
			fieldName: 'id',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 200,
		},
		{
			columnTitle: t('Categorias.Título'),
			fieldName: 'title',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 200,
		},
		{
			columnTitle: t('Categorias.Descrição'),
			fieldName: 'description',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 200,
		},
		{
			columnTitle: t('Categorias.Pai'),
			fieldName: 'parent',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 200,
		},
		{
			columnTitle: t('Categorias.Caminho'),
			fieldName: 'formattedRoute',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 90,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Categorias.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 90,
			dataType: 'date',
		},
	];

	const action1Props = {
		refresh: fetchData,
		isEditing: true,
		onSubmit: handleAddCategory,
	};
	const CustomHeaderComponentProps = {
		refresh: fetchData,
		onSubmit: handleAddCategory,
	};

	const renderReloadTableData = () => (
		<OfferToolTip
			title={`${t('Atualizar dados da tabela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={fetchData}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Cached
					sx={{
						animation: loading ? '$spin 2s linear infinite' : 'normal',
						'@keyframes spin': loading
							? {
									'0%': { transform: 'rotate(360deg)' },
									'100%': { transform: 'rotate(0deg)' },
								}
							: null,
						zIndex: !loading ? 2 : 'auto',
						fontSize: '22px',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return (
		<>
			{userData.roles.includes('Desenvolvedor') ? (
				<>
					<OfferTable
						colsData={colsData}
						tableData={tableData}
						CustomHeaderComponent={AddCategoriesModal}
						tableFooter
						windowHeader
						tableActions
						windowTitle={t('Categorias.Categorias')}
						ActionComponent1={AddCategoriesModal}
						ActionComponent1Props={action1Props}
						CustomHeaderComponentProps={CustomHeaderComponentProps}
						customLeftFooterComponent={renderReloadTableData()}
						loading={loading}
						switches
						switchesToolTip={switchesToolTip}
						dataFromSwitch={setReceivedDataSwitch}
						onSwitchChange={handleOpenToggleCategoriesDialog}
					/>
					<CategoriesToggleDialog
						open={openToggleDialog}
						setOpen={setOpenToggleDialog}
						data={receivedDataSwitch}
						onConfirm={handleConfirmStatus}
					/>
					{anchorEl && (
						<CategoriesPopover
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handlePopoverClose}
							routeData={popoverData}
						/>
					)}
				</>
			) : (
				<div style={{ marginLeft: '-75px' }}>
					<Unauthorized />
				</div>
			)}
		</>
	);
};

export default Categories;
