import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { Switch, Tooltip } from '@mui/material';
import { usePO } from '../utils/POContext';
import dateFormat from 'dateformat';
import countryNames from 'i18n-iso-countries';
import { withStyles } from '@mui/styles';

export const handleNumberAbbreviation = (
	i18nID: string,
	value: number
): string => {
	if (isNaN(value)) {
		return '0';
	}

	return Intl.NumberFormat(i18nID, {
		notation: 'compact',
		maximumFractionDigits: 2,
	}).format(value);
};

countryNames.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/pt.json')
);
countryNames.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/en.json')
);
countryNames.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/es.json')
);

export const handlePlatformINCountry = (data: string, i18nID: string) => {
	const platformInfo = data.split('|');
	const platformName =
		platformInfo[0].charAt(0).toUpperCase() +
		platformInfo[0].slice(1).toLowerCase();

	return `${platformName} - ${countryNames.getName(
		platformInfo[1],
		i18nID.substring(0, 2),
		{
			select: 'official',
		}
	)}`;
};

type ThemeIDType = 'main' | 'dark' | 'summer' | 'winter' | 'red' | 'classic';
interface IThemeConditionHandler {
	themeID: ThemeIDType;
	primaryThemeColor: string;
	alternateThemeColor: string; //Color used in else validation
}

interface INoThemeProps {
	noTheme: boolean;
	noThemeColor?: string;
}

export const HandleGetThemeColor = (
	themeCodition: IThemeConditionHandler,
	defaultTheme?: INoThemeProps
) => {
	const { selectedTheme } = usePO();
	if (defaultTheme?.noTheme) {
		return defaultTheme.noThemeColor;
	} else {
		if (themeCodition.themeID === selectedTheme.id) {
			return themeCodition.primaryThemeColor;
		} else {
			return themeCodition.alternateThemeColor;
		}
	}
};

type DateType = 'converted' | 'default';

export const convertDate = (
	date: string,
	userLanguageID: any,
	action: DateType
) => {
	const dateToISO: any = date?.replace('_', ' ').replace('_', ', ');
	switch (action) {
		case 'converted':
			return new Date(dateToISO).toLocaleDateString(userLanguageID, {
				weekday: 'long',
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			});
		case 'default':
			if (userLanguageID.includes('en')) {
				return dateFormat(date, 'yyyy/mm/dd');
			} else {
				return dateFormat(date, 'dd/mm/yyyy');
			}
	}
};

export const OfferToolTip = withStyles((theme) => {
	return {
		tooltip: {
			boxShadow: theme.shadows[3],
			fontSize: 14,
			padding: 12,
			color: (props) => props.color || '#fff',
			backgroundColor: (props) => props.backgroundColor || '#616161EB',
			border: (props) => props.border || 'transparent',
		},
		arrow: {
			color: (props) => props.backgroundColor || '#616161EB',
			'&::before': {
				border: (props) => props.border || 'transparent',
			},
		},
	};
})(Tooltip);

export const OfferSwitch = withStyles({
	switchBase: {
		'&$checked + $track': {
			backgroundColor: 'gray',
		},
	},
	checked: {},
	track: { backgroundColor: 'gray' },
})(Switch);

export const onlyNumbersRegex = /[^0-9]/g;

export const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/g;

export function TextMaskCNPJ(props) {
	const { inputRef, ...other } = props;
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'/',
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
			]}
			placeholderChar={'\u2000'}
		/>
	);
}
TextMaskCNPJ.propTypes = {
	inputRef: PropTypes.func.isRequired,
};

export function TextMaskDynamicRegNumber(props) {
	const { inputRef = () => {}, ...other } = props;

	const determineMask = (value) => {
		if (value.replace(/\D+/g, '').length === 11) {
			return [
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
			];
		}
		if (value.replace(/\D+/g, '').length === 14) {
			return [
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'/',
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
			];
		}
		return [
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
			/\d/,
		];
	};

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				if (typeof inputRef === 'function') {
					inputRef(ref ? ref.inputElement : null);
				}
			}}
			mask={(rawValue) => determineMask(rawValue)}
			placeholderChar={'\u2000'}
		/>
	);
}

TextMaskDynamicRegNumber.propTypes = {
	inputRef: PropTypes.func.isRequired,
};

export function TextMaskRegNumber(props) {
	const { inputRef, ...other } = props;
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
				/\w/,
			]}
			placeholderChar={'\u2000'}
		/>
	);
}
TextMaskRegNumber.propTypes = {
	inputRef: PropTypes.func.isRequired,
};

export function TextMaskCEP(props) {
	const { inputRef, ...other } = props;
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				if (typeof inputRef === 'function') {
					inputRef(ref ? ref.inputElement : null);
				}
			}}
			mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	);
}
TextMaskCEP.propTypes = {
	inputRef: PropTypes.func.isRequired,
};

export function TextMaskPhone(props) {
	const { inputRef, ...other } = props;
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				if (typeof inputRef === 'function') {
					inputRef(ref ? ref.inputElement : null);
				}
			}}
			mask={[
				'(',
				/\d/,
				/\d/,
				')',
				' ',
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,

				/\d/,
				/\d/,
				/\d/,
				/\d/,
			]}
			// mask={[/^(\d{2})(\d)/g]}
			placeholderChar={'\u2000'}
		/>
	);
}
TextMaskPhone.propTypes = {
	inputRef: PropTypes.func.isRequired,
};

export function checkIsDate(date: string) {
	const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
	if (!regex.test(date)) {
		return false;
	}

	const formatDate = new Date(date);
	return !isNaN(formatDate.getTime());
}

// Number Input Mask
export function TextMaskNumber(props) {
	const { inputRef, ...other } = props;
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	);
}
TextMaskNumber.propTypes = {
	inputRef: PropTypes.func.isRequired,
};

/** Table functions */

/**
 * Table header construction
 * Columns, sorting and styling functions
 */
export function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

export function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

export const formatPhoneNumber = (phoneNumber: string) => {
	if (phoneNumber !== null && phoneNumber !== undefined) {
		const cleaned = ('' + phoneNumber).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
		if (match) {
			return `(${match[1]}) ${match[2]}-${match[3]}`;
		}
		return cleaned;
	}
	return null;
};

export const statesUf = [
	{ value: 'AC', uf: 'AC', name: 'Acre' },
	{ value: 'AL', uf: 'AL', name: 'Alagoas' },
	{ value: 'AP', uf: 'AP', name: 'Amapá' },
	{ value: 'AM', uf: 'AM', name: 'Amazonas' },
	{ value: 'BA', uf: 'BA', name: 'Bahia' },
	{ value: 'CE', uf: 'CE', name: 'Ceará' },
	{ value: 'DF', uf: 'DF', name: 'Distrito Federal' },
	{ value: 'ES', uf: 'ES', name: 'Espírito Santo' },
	{ value: 'GO', uf: 'GO', name: 'Goiás' },
	{ value: 'MA', uf: 'MA', name: 'Maranhão' },
	{ value: 'MT', uf: 'MT', name: 'Mato Grosso' },
	{ value: 'MS', uf: 'MS', name: 'Mato Grosso do Sul' },
	{ value: 'MG', uf: 'MG', name: 'Minas Gerais' },
	{ value: 'PA', uf: 'PA', name: 'Pará' },
	{ value: 'PB', uf: 'PB', name: 'Paraíba' },
	{ value: 'PR', uf: 'PR', name: 'Paraná' },
	{ value: 'PE', uf: 'PE', name: 'Pernambuco' },
	{ value: 'PI', uf: 'PI', name: 'Piauí' },
	{ value: 'RJ', uf: 'RJ', name: 'Rio de Janeiro' },
	{ value: 'RN', uf: 'RN', name: 'Rio Grande do Norte' },
	{ value: 'RS', uf: 'RS', name: 'Rio Grande do Sul' },
	{ value: 'RO', uf: 'RO', name: 'Rondônia' },
	{ value: 'RR', uf: 'RR', name: 'Roraima' },
	{ value: 'SC', uf: 'SC', name: 'Santa Catarina' },
	{ value: 'SP', uf: 'SP', name: 'São Paulo' },
	{ value: 'SE', uf: 'SE', name: 'Sergipe' },
	{ value: 'TO', uf: 'TO', name: 'Tocantins' },
];

export const allCountries = [
	{ name: 'Afeganistão' },
	{ name: 'África do Sul' },
	{ name: 'Albânia' },
	{ name: 'Alemanha' },
	{ name: 'Andorra' },
	{ name: 'Angola' },
	{ name: 'Anguilla' },
	{ name: 'Antártida' },
	{ name: 'Antígua e Barbuda' },
	{ name: 'Arábia Saudita' },
	{ name: 'Argélia' },
	{ name: 'Argentina' },
	{ name: 'Armênia' },
	{ name: 'Aruba' },
	{ name: 'Austrália' },
	{ name: 'Áustria' },
	{ name: 'Azerbaijão' },
	{ name: 'Bahamas' },
	{ name: 'Bahrein' },
	{ name: 'Bangladesh' },
	{ name: 'Barbados' },
	{ name: 'Bélgica' },
	{ name: 'Belize' },
	{ name: 'Benin' },
	{ name: 'Bermudas' },
	{ name: 'Bielorrússia' },
	{ name: 'Bolívia' },
	{ name: 'Bósnia e Herzegovina' },
	{ name: 'Botsuana' },
	{ name: 'Brasil' },
	{ name: 'Brunei' },
	{ name: 'Bulgária' },
	{ name: 'Burquina Faso' },
	{ name: 'Burundi' },
	{ name: 'Butão' },
	{ name: 'Cabo Verde' },
	{ name: 'Camboja' },
	{ name: 'Canadá' },
	{ name: 'Catar' },
	{ name: 'Cazaquistão' },
	{ name: 'Chade' },
	{ name: 'Chile' },
	{ name: 'China' },
	{ name: 'Chipre' },
	{ name: 'Cidade do Vaticano' },
	{ name: 'Singapura' },
	{ name: 'Colômbia' },
	{ name: 'Comores' },
	{ name: 'Congo - Brazzaville' },
	{ name: 'Congo - Kinshasa' },
	{ name: 'Coreia do Norte' },
	{ name: 'Coreia do Sul' },
	{ name: 'Costa do Marfim' },
	{ name: 'Costa Rica' },
	{ name: 'Croácia' },
	{ name: 'Cuba' },
	{ name: 'Curaçao' },
	{ name: 'Dinamarca' },
	{ name: 'Djibuti' },
	{ name: 'Dominica' },
	{ name: 'Egito' },
	{ name: 'El Salvador' },
	{ name: 'Emirados Árabes Unidos' },
	{ name: 'Equador' },
	{ name: 'Eritreia' },
	{ name: 'Eslováquia' },
	{ name: 'Eslovênia' },
	{ name: 'Espanha' },
	{ name: 'Estados Unidos da América' },
	{ name: 'Estônia' },
	{ name: 'Etiópia' },
	{ name: 'Fiji' },
	{ name: 'Filipinas' },
	{ name: 'Finlândia' },
	{ name: 'França' },
	{ name: 'Gabão' },
	{ name: 'Gâmbia' },
	{ name: 'Gana' },
	{ name: 'Geórgia' },
	{ name: 'Geórgia do Sul e Ilhas Sandwich do Sul' },
	{ name: 'Gibraltar' },
	{ name: 'Granada' },
	{ name: 'Grécia' },
	{ name: 'Groenlândia' },
	{ name: 'Guadalupe' },
	{ name: 'Guam' },
	{ name: 'Guatemala' },
	{ name: 'Guernsey' },
	{ name: 'Guiana' },
	{ name: 'Guiana Francesa' },
	{ name: 'Guiné' },
	{ name: 'Guiné Bissau' },
	{ name: 'Guiné Equatorial' },
	{ name: 'Haiti' },
	{ name: 'Holanda' },
	{ name: 'Honduras' },
	{ name: 'Hong Kong, RAE da China' },
	{ name: 'Hungria' },
	{ name: 'Iêmen' },
	{ name: 'Ilhas Bouvet' },
	{ name: 'Ilha Christmas' },
	{ name: 'Ilha de Man' },
	{ name: 'Ilha Norfolk' },
	{ name: 'Ilhas Åland' },
	{ name: 'Ilhas Caiman' },
	{ name: 'Ilhas Cocos (Keeling)' },
	{ name: 'Ilhas Cook' },
	{ name: 'Ilhas Distantes dos EUA' },
	{ name: 'Ilha Heard e Ilha McDonald' },
	{ name: 'Ilhas Faroe' },
	{ name: 'Ilhas Malvinas' },
	{ name: 'Ilhas Marianas do Norte' },
	{ name: 'Ilhas Marshall' },
	{ name: 'Ilhas Pitcairn' },
	{ name: 'Ilhas Salomão' },
	{ name: 'Ilhas Turks e Caicos' },
	{ name: 'Ilhas Virgens Britânicas' },
	{ name: 'Ilhas Virgens dos EUA' },
	{ name: 'Índia' },
	{ name: 'Indonésia' },
	{ name: 'Irã' },
	{ name: 'Iraque' },
	{ name: 'Irlanda' },
	{ name: 'Islândia' },
	{ name: 'Israel' },
	{ name: 'Itália' },
	{ name: 'Jamaica' },
	{ name: 'Japão' },
	{ name: 'Jersey' },
	{ name: 'Jordânia' },
	{ name: 'Kuwait' },
	{ name: 'Laos' },
	{ name: 'Lesoto' },
	{ name: 'Letônia' },
	{ name: 'Líbano' },
	{ name: 'Libéria' },
	{ name: 'Líbia' },
	{ name: 'Liechtenstein' },
	{ name: 'Lituânia' },
	{ name: 'Luxemburgo' },
	{ name: 'Macau, RAE da China' },
	{ name: 'Macedônia do Norte' },
	{ name: 'Madagascar' },
	{ name: 'Malásia' },
	{ name: 'Malawi' },
	{ name: 'Maldivas' },
	{ name: 'Mali' },
	{ name: 'Malta' },
	{ name: 'Marrocos' },
	{ name: 'Martinica' },
	{ name: 'Maurício' },
	{ name: 'Mauritânia' },
	{ name: 'Mayotte' },
	{ name: 'México' },
	{ name: 'Mianmar (Birmânia)' },
	{ name: 'Micronésia' },
	{ name: 'Moçambique' },
	{ name: 'Moldávia' },
	{ name: 'Mônaco' },
	{ name: 'Mongólia' },
	{ name: 'Montenegro' },
	{ name: 'Montserrat' },
	{ name: 'Namíbia' },
	{ name: 'Nauru' },
	{ name: 'Nepal' },
	{ name: 'Nicarágua' },
	{ name: 'Níger' },
	{ name: 'Nigéria' },
	{ name: 'Niue' },
	{ name: 'Noruega' },
	{ name: 'Nova Caledônia' },
	{ name: 'Nova Zelândia' },
	{ name: 'Omã' },
	{ name: 'Países Baixos Caribenhos' },
	{ name: 'Palau' },
	{ name: 'Panamá' },
	{ name: 'Papua-Nova Guiné' },
	{ name: 'Paquistão' },
	{ name: 'Paraguai' },
	{ name: 'Peru' },
	{ name: 'Polinésia Francesa' },
	{ name: 'Polônia' },
	{ name: 'Porto Rico' },
	{ name: 'Portugal' },
	{ name: 'Quênia' },
	{ name: 'Quirguistão' },
	{ name: 'Quiribati' },
	{ name: 'Reino Unido' },
	{ name: 'República Centro-Africana' },
	{ name: 'República Dominicana' },
	{ name: 'República dos Camarões' },
	{ name: 'República Tcheca' },
	{ name: 'Reunião' },
	{ name: 'Romênia' },
	{ name: 'Ruanda' },
	{ name: 'Rússia' },
	{ name: 'Saara Ocidental' },
	{ name: 'Saint Pierre e Miquelon' },
	{ name: 'Samoa' },
	{ name: 'Samoa Americana' },
	{ name: 'San Marino' },
	{ name: 'Santa Helena' },
	{ name: 'Santa Lúcia' },
	{ name: 'São Bartolomeu' },
	{ name: 'São Cristóvão e Nevis' },
	{ name: 'São Martinho' },
	{ name: 'São Tomé e Príncipe' },
	{ name: 'São Vicente e Granadinas' },
	{ name: 'Senegal' },
	{ name: 'Serra Leoa' },
	{ name: 'Sérvia' },
	{ name: 'Seychelles' },
	{ name: 'Sint Maarten' },
	{ name: 'Síria' },
	{ name: 'Somália' },
	{ name: 'Sri Lanka' },
	{ name: 'Suazilândia' },
	{ name: 'Sudão' },
	{ name: 'Sudão do Sul' },
	{ name: 'Suécia' },
	{ name: 'Suíça' },
	{ name: 'Suriname' },
	{ name: 'Svalbard e Jan Mayen' },
	{ name: 'Tailândia' },
	{ name: 'Taiwan' },
	{ name: 'Tajiquistão' },
	{ name: 'Tanzânia' },
	{ name: 'Território Britânico do Oceano Índico' },
	{ name: 'Territórios Franceses do Sul' },
	{ name: 'Territórios palestinos' },
	{ name: 'Timor-Leste' },
	{ name: 'Togo' },
	{ name: 'Tokelau' },
	{ name: 'Tonga' },
	{ name: 'Trinidad e Tobago' },
	{ name: 'Tunísia' },
	{ name: 'Turcomenistão' },
	{ name: 'Turquia' },
	{ name: 'Tuvalu' },
	{ name: 'Ucrânia' },
	{ name: 'Uganda' },
	{ name: 'Uruguai' },
	{ name: 'Uzbequistão' },
	{ name: 'Vanuatu' },
	{ name: 'Venezuela' },
	{ name: 'Vietnã' },
	{ name: 'Wallis e Futuna' },
	{ name: 'Zâmbia' },
	{ name: 'Zimbábue' },
	{ name: 'Kosovo' },
];

export const converteNomeParaSigla = (nome) => {
	let sigla;

	switch (nome.trim()) {
		case 'Acre':
			sigla = 'AC';
			break;
		case 'Alagoas':
			sigla = 'AL';
			break;
		case 'Amazonas':
			sigla = 'AM';
			break;
		case 'Amapa':
			sigla = 'AP';
			break;
		case 'Bahia':
			sigla = 'BA';
			break;
		case 'Ceara':
			sigla = 'CE';
			break;
		case 'Distrito Federal':
			sigla = 'DF';
			break;
		case 'Espirito Santo':
			sigla = 'ES';
			break;
		case 'Goias':
			sigla = 'GO';
			break;
		case 'Maranhao':
			sigla = 'MA';
			break;
		case 'Minas Gerais':
			sigla = 'MG';
			break;
		case 'Mato Grosso do Sul':
			sigla = 'MS';
			break;
		case 'Mato Grosso':
			sigla = 'MT';
			break;
		case 'Para':
			sigla = 'PA';
			break;
		case 'Paraiba':
			sigla = 'PB';
			break;
		case 'Pernambuco':
			sigla = 'PE';
			break;
		case 'Piaui':
			sigla = 'PI';
			break;
		case 'Parana':
			sigla = 'PR';
			break;
		case 'Rio de Janeiro':
			sigla = 'RJ';
			break;
		case 'Rio Grande do Nrte':
			sigla = 'RN';
			break;
		case 'Rondonia':
			sigla = 'RO';
			break;
		case 'Roraima':
			sigla = 'RR';
			break;
		case 'Rio Grande do Sul':
			sigla = 'RS';
			break;
		case 'Santa Catarina':
			sigla = 'SC';
			break;
		case 'Sergipe':
			sigla = 'SE';
			break;
		case 'São Paulo':
			sigla = 'SP';
			break;
		case 'Sao Paulo':
			sigla = 'SP';
			break;
		case 'Tocantins':
			sigla = 'TO';
			break;
		default:
			sigla = nome;
			break;
	}
	return sigla;
};
