import React from 'react';
import Lottie from 'lottie-react';

interface Props {
	animationData: any;
	height?: string;
	width?: string;
	loop?: boolean;
}

const LottieAnimation: React.FC<Props> = ({
	animationData,
	height,
	width,
	loop,
}: Props) => {
	return (
		<div
			data-testid="LottieAnimation"
			style={{
				display: 'flex',
				width: width || 'auto',
				height: height || '100%',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Lottie
				autoplay={true}
				loop={loop || false}
				animationData={animationData.default} // Acessando a animação corretamente
				style={{
					width: width || 'auto',
					height: height || '100%',
				}}
			/>
		</div>
	);
};

export default LottieAnimation;
