import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Zoom } from '@mui/material';
import {
	Cached,
	FirstPage,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	LastPage,
	PlaylistAddRounded,
} from '@mui/icons-material/';
import { api, setupRequestToken } from '../../../utils/api';
import { Line } from '../../../styles';
import { OfferToolTip } from '../../../helpers';
import { useAuth0 } from '../../../react-auth0-spa';
import { usePO } from '../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import CallDialogDetails from './SavedSearchesDetails/CallDialogDetails';
import countryNames from 'i18n-iso-countries';
import DisableSearchDialog from './DisableSearchDialog';
import EditSearchModal from './EditSearchModal';
import OfferTable from '../../../components/OfferTable';
import OpenCurrentSearch from './OpenCurrentSearch';
import RunSearchDialog from './RunSearchDialog';
import SavedSearchesDetails from './SavedSearchesDetails';
import SelectActiveClient from '../../../components/SelectClient';
import TableSearch from '../../../components/TableSearch';
import FilterBar from './SearchesFilterBar';
import BackDropLoading from '../../../components/BackdropLoading';

const SavedSearches: React.FC = () => {
	const { token } = useAuth0();
	setupRequestToken(api, token);

	const { selectedClient, userData, selectedTheme } = usePO();
	const { t } = useTranslation();

	/** State variable that receives the array of selected table items */
	const [tableData, setTableData] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);

	/** API pagination table controls */
	const [tableLength, setTableLength] = useState(1);
	const [displayCurrentPage, setDisplayCurrentPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentPageChange, setCurrentPageChange] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [refreshTable, setRefreshTable] = useState(0);
	const [receivedDataSwitch, setReceivedDataSwitch] = useState<any>();
	const [open, setOpen] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1
	);
	const rowsPerPageOptions = [
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1,
		50,
		100,
	];
	const [isFilterBarOpen, setIsFilterBarOpen] = useState(false);
	const [hasPermissions, setHasPermissions] = useState(false);

	const [rowData, setRowData] = useState<any>();
	const [openModal, setOpenModal] = useState(false);

	const handleOpenFilterBar = () => {
		setIsFilterBarOpen(!isFilterBarOpen);
	};

	useEffect(() => {
		// Check permissions to show or hide the run search button
		if (userData?.roles) {
			if (
				userData.roles.includes('Desenvolvedor') ||
				userData.roles.includes('Administrador da ferramenta')
			) {
				setHasPermissions(true);
			}
		}
	}, [userData]);

	const switchesToolTip = {
		true: t('Buscas.Tabs.Buscas Salvas.Busca ativada'),
		false: t('Buscas.Tabs.Buscas Salvas.Busca desativada'),
	};

	const colsData = [
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.ID da busca'),
			fieldName: 'searchID',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 70,
		},
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Nome'),
			fieldName: 'title',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 150,
		},
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Plataforma'),
			fieldName: 'platform',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
		},
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Termos Buscados'),
			fieldName: 'terms',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
		},
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Periodicidade'),
			fieldName: 'period',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 80,
		},
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Chave de busca'),
			fieldName: 'tagID',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
		},
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Data'),
			fieldName: 'date',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 90,
			dataType: 'date',
		},
	];

	const renderPeriodicity = (data: number) => {
		switch (data) {
			case -1:
				return t('Buscas.Tabs.Buscas Salvas.Única');
			default:
				if (data === 1) {
					return `${data} ${t('Buscas.Tabs.Nova Busca.Dia')}`;
				} else if (data > 1) {
					return `${data} ${t('Buscas.Tabs.Nova Busca.Dias')}`;
				}
				return null;
		}
	};

	const renderSearchType = (data: string) => {
		switch (data) {
			case 'BUSCADOR':
				return t('Buscas.Tabs.Buscas Salvas.Buscador');
			case 'IDE':
				return 'IDE';
			default:
				break;
		}
		return null;
	};

	countryNames.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/pt.json')
	);
	countryNames.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/en.json')
	);
	countryNames.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/es.json')
	);

	const handleSetOpen = () => {
		setOpenModal(!openModal);
	};

	const handleSetRowData = (rowData) => {
		setRowData(rowData);
	};

	const renderPlatformInfo = (data) => {
		const platformInfo = data.split('|');
		const platformName =
			platformInfo[0].charAt(0).toUpperCase() +
			platformInfo[0].slice(1).toLowerCase();

		return `${platformName} - ${countryNames.getName(
			platformInfo[1],
			userData.i18nID.substring(0, 2),
			{
				select: 'official',
			}
		)}`;
	};

	// get current timestamp in iso format
	const getTimestamp = () => {
		const date = new Date();
		return date.toISOString();
	};

	// TODO Make a hook for a global loading state that includes both setLoading and updateCursorMode combined controls
	const buildTableData = (data: any, totalHits?: number) => {
		const tempData: any[] = [];
		if (totalHits) {
			setTableLength(totalHits);
		}
		setDisplayCurrentPage(currentPage);
		setLoading(true);

		if (data.map) {
			setTableData([]);
			data.map((el, index) => {
				const formatSearch = {
					title: el.title,
					platform: renderPlatformInfo(el.platformINcountryID),
					terms: el.terms,
					excludingTerms: el.excludingTerms,
					period: renderPeriodicity(el.period),
					searchTypeName: renderSearchType(el.searchTypeID),
					searchTypeID: el.searchTypeID,
					date: el.lastSearch || getTimestamp(),
					searchID: el.id,
					clientID: el.clientID,
					description: el.description,
					tagID: el.tagID,
					platformINcountryID: el.platformINcountryID,
					periodID: el.period,
					inProgress: el.lastSearch === null,
					ISactive: el.ISactive,
					rowIndex: index,
				};
				tempData.push(formatSearch);
				return null;
			});
			setTableData(tempData);
			setLoading(false);
		}
	};

	const handleRefreshTable = async () => {
		setRefreshTable(refreshTable + 1);
	};

	const handleChangeRowsPerPage = (event) => {
		if (currentPage > 0) {
			setCurrentPage(0);
			setDisplayCurrentPage(0);
			setLoading(true);
			setRowsPerPage(event.target.value);
		}
		if (currentPage === 0) {
			setRowsPerPage(event.target.value);
		}
	};

	const handleChangePage = (
		event: any,
		newPage: React.SetStateAction<number>
	) => {
		setLoading(true);
		setCurrentPage(newPage);
		setCurrentPageChange(currentPageChange + 1);
	};

	const handleKeyArrowStyle = (disableVerification) => {
		if (disableVerification) {
			return selectedTheme.id === 'dark' ? selectedTheme.textColorDisable : '';
		} else {
			return selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '';
		}
	};

	function TablePaginationActions() {
		const handleFirstPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, 0);
		};

		const handleBackButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, currentPage - 1);
		};

		const handleNextButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, currentPage + 1);
		};

		const handleLastPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(
				event,
				Math.max(0, Math.ceil(tableLength / rowsPerPage) - 1)
			);
		};
		return (
			<Box
				style={{
					flexShrink: 0,
					marginLeft: 30,
				}}
			>
				<Line>
					<IconButton
						onClick={handleFirstPageButtonClick}
						disabled={currentPage === 0}
						aria-label="first page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<FirstPage
							style={{ color: handleKeyArrowStyle(currentPage === 0) }}
						/>
					</IconButton>
					<IconButton
						onClick={handleBackButtonClick}
						disabled={currentPage === 0}
						aria-label="previous page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<KeyboardArrowLeft
							style={{ color: handleKeyArrowStyle(currentPage === 0) }}
						/>
					</IconButton>
					<IconButton
						onClick={handleNextButtonClick}
						disabled={currentPage >= Math.ceil(tableLength / rowsPerPage) - 1}
						aria-label="next page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<KeyboardArrowRight
							style={{
								color: handleKeyArrowStyle(
									currentPage >= Math.ceil(tableLength / rowsPerPage) - 1
								),
							}}
						/>
					</IconButton>
					<IconButton
						onClick={handleLastPageButtonClick}
						disabled={currentPage >= Math.ceil(tableLength / rowsPerPage) - 1}
						aria-label="last page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<LastPage
							style={{
								color: handleKeyArrowStyle(
									currentPage >= Math.ceil(tableLength / rowsPerPage) - 1
								),
							}}
						/>
					</IconButton>
				</Line>
			</Box>
		);
	}

	const action2and4Props = {
		reloadTable: handleRefreshTable,
	};

	const action4Props = {
		setRowData: handleSetRowData,
		setOpen: handleSetOpen,
	};

	const renderFilter = () => (
		<Line>
			<Typography
				style={{
					margin: '0 5px 0 20px',
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			>
				{t('Buscas.Tabs.Buscas Salvas.Cliente')}:
			</Typography>
			<SelectActiveClient width="200px" />
		</Line>
	);

	const renderReloadTableData = () => (
		<Line>
			<OfferToolTip
				title={`${t('Buscas.Tabs.Buscas Salvas.Atualizar dados da tabela')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={handleRefreshTable}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<Cached
						sx={{
							animation: loading ? '$spin 2s linear infinite' : 'normal',
							'@keyframes spin': loading
								? {
										'0%': {
											transform: 'rotate(360deg)',
										},
										'100%': {
											transform: 'rotate(0deg)',
										},
									}
								: null,
							zIndex: !loading ? 2 : 'auto',
						}}
						style={{
							fontSize: 22,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					/>
				</IconButton>
			</OfferToolTip>
			<OfferToolTip
				title={
					!isFilterBarOpen
						? t('Mostrar barra de filtros')
						: t('Ocultar barra de filtros')
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={handleOpenFilterBar}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<PlaylistAddRounded
						style={{
							marginRight: -5,
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					/>
				</IconButton>
			</OfferToolTip>
		</Line>
	);

	return (
		<>
			<OfferTable
				customTablePagination={TablePaginationActions}
				customTableLength={tableLength}
				customSearch={
					<TableSearch
						setSearchText={setSearchText}
						customTooltip="Termos presentes no título da busca"
					/>
				}
				customRowsPerPageOptions={rowsPerPageOptions}
				customRowsPerPage={rowsPerPage}
				handleChangeCustomRowsPerPage={handleChangeRowsPerPage}
				customCurrentPage={displayCurrentPage}
				tableData={tableData}
				colsData={colsData}
				tableActions
				tableFooter
				customRightFooterComponent={renderFilter()}
				customLeftFooterComponent={renderReloadTableData()}
				loading={loading}
				customNoDataMessage={
					// eslint-disable-next-line no-nested-ternary
					selectedClient
						? t('Buscas.Tabs.Buscas Salvas.Sem buscas para este cliente')
						: t('Buscas.Tabs.Buscas Salvas.Selecione um cliente')
				}
				ActionComponent1={OpenCurrentSearch}
				ActionComponent2={hasPermissions ? RunSearchDialog : null}
				ActionComponent2Props={action2and4Props}
				ActionComponent3={EditSearchModal}
				ActionComponent3Props={action2and4Props}
				ActionComponent4={CallDialogDetails}
				ActionComponent4Props={action4Props}
				switches
				switchesToolTip={switchesToolTip}
				dataFromSwitch={setReceivedDataSwitch}
				onSwitchChange={() => setOpen(true)}
			/>
			<FilterBar
				buildTableData={buildTableData}
				currentPage={currentPage}
				isFilterBarOpen={isFilterBarOpen}
				loading={loading}
				refreshTable={refreshTable}
				rowsPerPage={rowsPerPage}
				searchText={searchText || ''}
				setIsFilterBarOpen={setIsFilterBarOpen}
				setLoading={setLoading}
			/>
			<div>
				{tableData.length > 0 && loading && <BackDropLoading smallBackdrop />}
			</div>
			<DisableSearchDialog
				open={open}
				setOpen={setOpen}
				rowData={receivedDataSwitch}
				refresh={handleRefreshTable}
			/>

			{rowData ? (
				<SavedSearchesDetails
					open={openModal}
					setOpen={handleSetOpen}
					rowData={rowData}
				/>
			) : null}
		</>
	);
};

export default SavedSearches;
