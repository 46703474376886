import React from 'react';
import {
	TextField,
	Autocomplete,
	TextFieldVariants,
	createTheme,
	ThemeProvider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../utils/POContext';
import { useOfferPlatformData } from '../../hooks/OfferPlatformData';

interface Props {
	width?: string;
	variant?: TextFieldVariants;
}
const SelectClient: React.FC<Props> = ({ width, variant }: Props) => {
	const { t } = useTranslation();
	const { selectedClient, userActiveClients, selectedTheme } = usePO();
	const { updateSelectedClient } = useOfferPlatformData();

	const useStyles = makeStyles(() => ({
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const classes = useStyles();

	const theme = createTheme({
		components: {
			MuiAutocomplete: {
				styleOverrides: {
					noOptions: {
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: '#000',
					},
				},
			},
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<Autocomplete
				key={`${selectedClient?.id}`}
				data-testid="selectClientSelector"
				id="client-select"
				sx={{
					width: width || '100%',
					margin: '10px',
					cursor: 'pointer',
					'& .MuiAutocomplete-noOptions': {
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: 'inherit',
					},
				}}
				classes={{
					paper: classes.option,
				}}
				options={userActiveClients}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				value={selectedClient}
				onChange={(_, value) => {
					updateSelectedClient(value);
				}}
				autoHighlight
				autoComplete
				autoSelect
				disableClearable
				loadingText={t('Carregando...')}
				selectOnFocus
				getOptionLabel={(option) => (option as { name: string }).name || ''}
				renderInput={(params) => (
					<TextField
						{...params}
						variant={variant || 'standard'}
						name="selectedClient"
						data-testid="selectClientInput"
						size="small"
						inputProps={{
							...params.inputProps,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
						}}
						sx={{
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
					/>
				)}
			/>
		</ThemeProvider>
	);
};

export default SelectClient;
