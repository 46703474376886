import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../assets/animations/Loading/loading_offer.json';
import { Line } from '../../styles';

interface Props {
	height?: number | string;
	width?: number | string;
	loop?: boolean;
	autoplay?: boolean;
}

const LottieAnimation: React.FC<Props> = ({
	height = '100%',
	width = 'auto',
	loop = true,
	autoplay = true,
}: Props) => {
	return (
		<Line
			data-testid="LoadingOfferComponent"
			style={{
				display: 'flex',
				height,
				width,
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Lottie
				animationData={animationData}
				loop={loop}
				autoplay={autoplay}
				style={{
					height,
					width,
				}}
			/>
		</Line>
	);
};

export default LottieAnimation;
