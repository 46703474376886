import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
	Button,
	IconButton,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	Zoom,
	FormControl,
	TextField,
	DialogTitle,
	Autocomplete,
} from '@mui/material';
import {
	CloseRounded,
	ThumbUpRounded,
	ThumbDownRounded,
} from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../components/LoadingDots';
import { api, isAxiosError, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';

import { Column, Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { OfferToolTip } from '../../../../helpers';
import SelectClient from '../../../../components/SelectClient';
import {
	ClassificationType,
	ErrorResponse,
	TagObjectType,
} from '../../../../interfaces';
import TagSelector from '../../../../components/TagSelector';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateGoodCards,
	updateNotGoodCards,
} from '../../../../redux/slices/cardFeedbackSlice';

interface Props {
	offerData: any;
	cardAsGood?: boolean;
	handleRemoveCardAsGood: () => void;
	setDisabledCards?: any;
	disabledCards?: any;
	markedAsGoodCards?: any;
	setMarkedAsGoodCards?: any;
}

const DenounceConfirmationDialog: React.FC<Props> = ({
	offerData,
	cardAsGood,
	handleRemoveCardAsGood,
}: Props) => {
	const { token } = useAuth0();
	const { t } = useTranslation();
	const {
		selectedTheme,
		classificationTypes,
		selectedClient,
		tagsActiveClient,
		userData,
	} = usePO();
	const { enqueueSnackbar } = useSnackbar();

	setupRequestToken(api, token);

	const useStyles = makeStyles(() => ({
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		dialogPaper: {
			maxHeight: '90vh',
		},
	}));

	const classes = useStyles();

	/** Filter data on Card to display Selected Client's only tags */
	const filterClientTags = offerData?.tags?.filter((tag) => {
		return tag.clientID === selectedClient.id;
	});

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [denounceObs, setDenounceObs] = useState('');
	const [denounceValue, setDenounceValue] =
		useState<ClassificationType | null>();
	const [cardTags, setCardTags] = useState<TagObjectType[]>(
		filterClientTags || []
	);
	const [loadingTags, setLoadingTags] = useState(false);
	const [tagFieldError, setTagFieldError] = useState(false);

	const dispatch = useDispatch();

	const notGoodCardsList = useSelector(
		(state: any) => state.cardFeedback.notGoodCardsList
	);

	const goodCardsList = useSelector(
		(state: any) => state.cardFeedback.goodCardsList
	);

	const handleSaveTags = async () => {
		const success: any = [];
		const error: any = [];
		const payload = {
			offerIDs: [offerData.offerID],
			tagIDs: cardTags.map((el: TagObjectType) => el.tagID),
		};
		try {
			setLoadingTags(true);
			const tagsResponse = await api.patch(
				`/tagINoffer/elasticsearch?clientID=${selectedClient.id}`,
				payload,
				{
					timeout: 300 * 1000,
				}
			);
			tagsResponse.data.map((el) => {
				if (el.status >= 400) {
					error.push(el.detail);
				} else {
					success.push(el.detail);
				}
				return null;
			});
			setLoadingTags(false);
			enqueueSnackbar(t(`Classificações.${tagsResponse.data[0].detail}`), {
				variant: 'success',
			});
			if (error.length > 0) {
				setTimeout(() => {
					enqueueSnackbar(error[0], { variant: 'error' });
				}, 3000);
			}
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse.data.detail, { variant: 'error' });
				}
			}
		}
	};

	const handleDenounceReasonChange = (value) => {
		if (value) {
			setDenounceValue(value);
		}
	};

	const handleObservationChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setDenounceObs(event.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClickPermit = () => {
		setDenounceValue({
			id: 'PSTLG',
			name: 'Post Legal',
			description: 'Post reconhecidamente legal',
			ISactive: true,
			ISgood: true,
		});
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			setDenounceValue(null);
			setDenounceObs('');
		}, 300);
	};

	const handleSave = async () => {
		setLoading(true);
		const payload = {
			offerID: [offerData.offerID],
			classificationModel: {
				classificationTypeID: denounceValue?.id,
				denounceStatusID: 'CLASSIFICADA',
				clientID: selectedClient.id,
				observation: denounceObs,
			},
		};
		const response = await api.post('/offerClassification', payload);
		// TODO Implement library to show non numeric error codes
		response.data.map((item) => {
			if (item.status < 300) {
				/** If it's marking card as good */
				if (denounceValue?.id === 'PSTLG') {
					enqueueSnackbar(item.detail, { variant: 'success' });
					setOpen(false);
					/** Changes card footer to Classified as Good */
					dispatch(updateGoodCards([...goodCardsList, offerData.offerID]));
				}
				/** If it's a denunciation */
				if (denounceValue?.id !== 'PSTLG') {
					enqueueSnackbar(item.detail, { variant: 'success' });
					setOpen(false);
					/** Makes card less opaque and not clickable */
					dispatch(
						updateNotGoodCards([...notGoodCardsList, offerData.offerID])
					);
				}
			}
			if (item.status >= 400) {
				enqueueSnackbar(item.detail, { variant: 'error' });
			}
			return null;
		});
		setLoading(false);
	};

	const handleRemove = async () => {
		setLoading(true);
		try {
			const response = await api.get(
				`/offerClassification/clients/${selectedClient.id}/offers/${offerData.offerID}`
			);
			await api.delete(
				`/offerClassification/${response.data.id}/clients/${selectedClient?.id}`
			);
			dispatch(
				updateGoodCards(
					goodCardsList.filter((offerID) => offerID !== offerData.offerID)
				)
			);
			handleRemoveCardAsGood();
			enqueueSnackbar(
				t('Classificações.Anúncio removido da lista de permitidos.'),
				{ variant: 'success' }
			);
			handleClose();
			setLoading(false);
		} catch (error) {
			enqueueSnackbar(
				t(
					'Classificações.Erro ao remover anúncio da lista. Tente novamente mais tarde.'
				),
				{ variant: 'error' }
			);

			handleClose();
			setLoading(false);
		}
	};

	const handleClickDenounce = () => {
		// check if role is admin
		if (userData.roles.includes('Analista de posts')) {
			if (cardTags.length > 0) {
				setTagFieldError(false);
				handleSave();
			} else {
				setTagFieldError(true);
				enqueueSnackbar(t('Classificações.Adicione uma ou mais etiquetas'), {
					variant: 'warning',
				});
			}
		} else {
			if (denounceValue?.id !== '') {
				handleSave();
			}
			if (denounceValue?.id === '') {
				enqueueSnackbar(
					t(
						'Classificações.Por favor, especifique um motivo para a classificação.'
					),
					{ variant: 'success' }
				);
			}
		}
	};

	const renderDenounceReasons = () => {
		if (selectedClient !== undefined && selectedClient !== null) {
			if (denounceValue?.id !== 'PSTLG' && classificationTypes?.length) {
				return (
					<FormControl style={{ marginTop: 5, width: 510 }}>
						<Autocomplete
							id="selectReasonDenounce"
							data-testid="selectReasonDenounce"
							style={{ width: '100%', marginTop: 5, cursor: 'pointer' }}
							options={classificationTypes}
							onChange={(_, newValue) => {
								handleDenounceReasonChange(newValue);
							}}
							classes={{
								paper: classes.option,
							}}
							value={denounceValue}
							autoHighlight
							autoComplete
							autoSelect
							selectOnFocus
							getOptionLabel={(option: ClassificationType) => {
								return t(`ClassificationType.${option?.id as string}`);
							}}
							renderOption={(props, option) => (
								<li {...props}>
									{t(`ClassificationType.${option?.id as string}`)}
								</li>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									style={{
										borderRadius: '4px',
										overflow: 'hidden',
									}}
									name="selectReasonDenounceText"
									size="small"
									placeholder={t('Classificações.Escolha um motivo')}
									variant="outlined"
									inputProps={{
										...params.inputProps,
										style: {
											color:
												selectedTheme.id === 'dark'
													? selectedTheme?.textColorHigh
													: '',
										},
									}}
									sx={{
										'& .MuiInputBase-root': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
											},
											':hover': {
												'& > fieldset': {
													borderColor:
														selectedTheme.id === 'dark' ? '#fff' : '#000',
												},
											},
										},
										'.MuiSvgIcon-root ': {
											fill:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorHigh,
										},
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									}}
								/>
							)}
						/>
						{denounceValue?.id !== '' && denounceValue?.id !== 'PSTLG' ? (
							<Line style={{ height: 60 }}>
								<Typography
									variant="subtitle2"
									style={{
										opacity: 0.8,
										margin: '10px 0 -10px 10px',
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorHigh,
									}}
								>
									{classificationTypes.map((item) =>
										item.id === denounceValue
											? t(`ClassificationTypeDescription.${item.description}`)
											: null
									)}
								</Typography>
							</Line>
						) : (
							<Line style={{ height: 60 }} />
						)}

						<TextField
							id="observation"
							name="observation"
							label={t('Classificações.Observações')}
							value={denounceObs}
							multiline
							rows={2}
							onChange={handleObservationChange}
							variant="outlined"
							style={{ marginTop: 35 }}
							InputLabelProps={{
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							inputProps={{
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							sx={{
								'& .MuiOutlinedInput-root': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							}}
						/>
						<Line style={{ marginTop: 20 }}>
							<Typography
								sx={{
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								}}
							>
								{t('Classificações.Etiquetas')}:
							</Typography>
						</Line>
						<Line>
							<TagSelector
								error={!!tagFieldError}
								dataArray={cardTags}
								setDataArray={setCardTags}
								handleSaveTags={() => handleSaveTags()}
								suggestions={tagsActiveClient}
								autoSave
								maxItems={6}
								rows={1}
								limitTags={3}
							/>
							<div
								style={{
									position: 'absolute',
									right: 5,
									bottom: 105,
									display: loadingTags ? 'block' : 'none',
								}}
							>
								<LoadingDots width={40} loop />
							</div>
						</Line>
						<Line style={{ marginTop: 20 }}>
							<Typography
								sx={{
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								}}
							>
								{t('Classificações.Cliente')}:
							</Typography>
						</Line>
						<TextField
							size="small"
							variant="outlined"
							name="selectedClient"
							value={selectedClient?.name}
							style={{ width: '100%', fontWeight: 'bold', marginBottom: 20 }}
							InputProps={{
								readOnly: true,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							sx={{
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							}}
						/>
					</FormControl>
				);
			}
			if (denounceValue?.id === 'PSTLG')
				return cardAsGood || goodCardsList.includes(offerData.offerID) ? (
					<Typography
						variant="h6"
						sx={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{t('Classificações.Remover o anúncio da lista de permitidos')}?
					</Typography>
				) : (
					<>
						<Typography
							variant="h6"
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{t(
								'Classificações.Classificar o anúncio como permitido para o cliente'
							)}
							:
						</Typography>
						<Typography
							variant="h6"
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								marginLeft: '5px',
							}}
						>
							{`${selectedClient.name || ''}`}
						</Typography>
						<Typography
							variant="h6"
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							?
						</Typography>
					</>
				);
		}
		return (
			<Column style={{ rowGap: 10 }}>
				<Typography
					variant="h6"
					sx={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Escolha um cliente para classificar o anúncio')}:
				</Typography>
				<SelectClient />
			</Column>
		);
	};

	const handleFooter = () => {
		if (selectedClient !== undefined && selectedClient !== null) {
			if (denounceValue?.id !== 'PSTLG') {
				return (
					<Line style={{ justifyContent: 'space-between' }}>
						<Button
							size="small"
							onClick={handleClose}
							disabled={loading}
							style={{ color: loading ? 'gray' : selectedTheme.error }}
						>
							{t('Classificações.Cancelar')}
						</Button>
						{loading ? (
							<LoadingDots height={30} width={70} loop />
						) : (
							<Button
								size="small"
								onClick={handleClickDenounce}
								data-testid="DenounceOffer"
								style={{
									border: `1px solid ${
										selectedTheme.id === 'dark'
											? selectedTheme.footerLine
											: 'transparent'
									}`,
									color:
										selectedTheme.tone === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.foreground,
									background:
										selectedTheme.id === 'dark'
											? selectedTheme.primaryDark
											: selectedTheme.primaryDark,
								}}
							>
								{t('Classificações.Classificar')}
							</Button>
						)}
					</Line>
				);
			}
			if (denounceValue?.id === 'PSTLG') {
				return (
					<Line style={{ justifyContent: 'space-between' }}>
						<Button
							size="small"
							onClick={handleClose}
							disabled={loading}
							style={{ color: loading ? 'gray' : selectedTheme.error }}
						>
							{t('Classificações.Cancelar')}
						</Button>
						{loading ? (
							<LoadingDots height={30} width={70} loop />
						) : (
							<Button
								size="small"
								style={{
									border: `1px solid ${
										selectedTheme.id === 'dark'
											? selectedTheme.footerLine
											: 'transparent'
									}`,
									color:
										selectedTheme.tone === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.foreground,
									background:
										selectedTheme.id === 'dark'
											? selectedTheme.primaryDark
											: selectedTheme.primaryDark,
								}}
								onClick={
									cardAsGood || goodCardsList.includes(offerData.offerID)
										? handleRemove
										: handleSave
								}
							>
								{cardAsGood || goodCardsList.includes(offerData.offerID)
									? `${t('Classificações.Remover')}`
									: `${t('Classificações.Classificar')}`}
							</Button>
						)}
					</Line>
				);
			}
		}
		return (
			<Line style={{ justifyContent: 'flex-start' }}>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Classificações.Cancelar')}
				</Button>
			</Line>
		);
	};

	const renderThumbsUp = () => {
		return (
			<OfferToolTip
				title={
					cardAsGood || goodCardsList.includes(offerData.offerID)
						? `${t('Classificações.Remover da lista de permitidos')}`
						: `${t('Classificações.Permitir')}`
				}
				aria-label="inativo"
				arrow
				enterDelay={500}
				enterNextDelay={500}
				TransitionComponent={Zoom}
			>
				<IconButton
					sx={{
						padding: '8px',
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
					onClick={handleClickPermit}
				>
					<ThumbUpRounded
						style={{
							margin: 2,
							fontSize: 20,
							color: selectedTheme.success,
						}}
					/>
				</IconButton>
			</OfferToolTip>
		);
	};

	const renderThumbsDown = () =>
		cardAsGood || goodCardsList.includes(offerData.offerID) ? null : (
			<OfferToolTip
				title={`${t('Classificações.Classificar')}`}
				aria-label="inativo"
				arrow
				enterDelay={500}
				enterNextDelay={500}
				TransitionComponent={Zoom}
			>
				<IconButton
					sx={{
						padding: '8px',
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
					onClick={handleClickOpen}
					data-testid="cardDenounceFront"
				>
					<ThumbDownRounded
						style={{ margin: 2, fontSize: 20, color: selectedTheme.error }}
					/>
				</IconButton>
			</OfferToolTip>
		);

	const renderHeader = () => (
		<>
			{denounceValue?.id !== 'PSTLG' &&
			selectedClient !== undefined &&
			selectedClient !== null ? (
				<DialogTitle
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						height: 60,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.tableHead
								: selectedTheme.gradient,
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.primary
								: selectedTheme.foreground,
					}}
					id="max-width-dialog-title"
				>
					<Line
						style={{
							justifyContent: 'space-between',
						}}
					>
						<Typography
							noWrap
							style={{
								color:
									selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
								fontSize: 18,
								fontWeight: 'bold',
								maxWidth: '90%',
							}}
						>
							{t('Classificações.Detalhes da classificação')}:
						</Typography>
						<IconButton
							onClick={handleClose}
							style={{ marginRight: '-20px' }}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<CloseRounded
								style={{
									color:
										selectedTheme.id === 'main'
											? selectedTheme.primary
											: 'white',
								}}
							/>
						</IconButton>
					</Line>
				</DialogTitle>
			) : null}
		</>
	);

	const renderContent = () => (
		<DialogContent
			style={{
				background: selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
				padding: '20px 30px',
			}}
		>
			<Line style={{ justifyContent: 'center' }}>
				{renderDenounceReasons()}
			</Line>
		</DialogContent>
	);

	return (
		<div>
			{renderThumbsUp()}
			{renderThumbsDown()}
			<Dialog
				open={open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				classes={{ paper: classes.dialogPaper }}
				disableEscapeKeyDown
			>
				{renderHeader()}
				{renderContent()}
				<DialogActions
					style={{
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme?.footerLine
								: '#f2f2f2'
						}`,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.overlay3dp
								: selectedTheme.foreground,
						padding: 10,
					}}
				>
					{handleFooter()}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default DenounceConfirmationDialog;
