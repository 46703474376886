import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../utils/POContext';
import { Line } from '../../../styles';
import LoadingDots from '../../../components/LoadingDots';

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
	data: any;
	onConfirm: (status: boolean) => void;
}

const CategoriesToggleDialog: React.FC<Props> = ({
	open,
	setOpen,
	data,
	onConfirm,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();
	const [loading, setLoading] = useState(false);
	const [ISactive, setISactive] = useState(false);

	const handleConfirm = () => {
		setLoading(true);
		const newStatus = !ISactive;
		setISactive(newStatus);
		onConfirm(newStatus);
		setLoading(false);
		setOpen(false);
	};

	useEffect(() => {
		if (data) {
			setISactive(data.ISactive);
		}
	}, [data]);

	return (
		<Dialog open={open}>
			<DialogContent
				style={{
					padding: 22,
					width: '100%',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
				}}
			>
				<Typography
					align="center"
					variant="h6"
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					}}
				>
					{ISactive
						? `${t('Categorias.Desativar a categoria')}: ${data?.title}`
						: `${t('Categorias.Ativar a categoria')}: ${data?.title}`}
					?
				</Typography>
			</DialogContent>
			<DialogActions
				style={{
					borderTop: 'solid',
					borderTopWidth: 1,
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
				}}
			>
				<Line
					style={{
						justifyContent: 'space-between',
						padding: 5,
					}}
				>
					<Button
						disabled={loading}
						onClick={() => setOpen(false)}
						style={{ color: selectedTheme.error }}
					>
						{t('Categorias.Cancelar')}
					</Button>
					<Button
						disabled={loading}
						onClick={handleConfirm}
						style={{
							color:
								selectedTheme?.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					>
						{loading ? (
							<LoadingDots height={25} width={60} loop />
						) : (
							t('Categorias.Confirmar')
						)}
					</Button>
				</Line>
			</DialogActions>
		</Dialog>
	);
};

export default CategoriesToggleDialog;
