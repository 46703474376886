import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const searchesFilters = async (searchPayload, clientID) => {
	const filteredQueryParams = Object.fromEntries(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		Object.entries(searchPayload).filter(([_, value]) => value != null)
	);
	try {
		const response = await api.get(`/searches/clients/${clientID}`, {
			params: filteredQueryParams,
			timeout: 2 * 60 * 1000,
		});
		return { success: true, data: response.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const fetchAvailablePlatforms = async () => {
	try {
		const response = await api.get('/platformINcountry/allplatforms/countries');
		return { data: response.data, success: true };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
