import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../assets/animations/Loading/loading_dots.json';
import { Line } from '../../styles';

interface Props {
	height?: number;
	width?: number;
	loop?: boolean;
}

const LottieAnimation: React.FC<Props> = ({ height, width, loop }: Props) => {
	return (
		<Line
			data-testid="LoadingDotsComponent"
			style={{
				display: 'flex',
				height: height || '100%',
				width: width || 'auto',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Lottie
				autoplay
				loop={loop || false}
				animationData={animationData}
				style={{
					height: height || '100%',
					width: width || 'auto',
				}}
			/>
		</Line>
	);
};

export default LottieAnimation;
