import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	LinearProgress,
	IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../utils/POContext';
import { Column, Line } from '../../../styles';
import Panel from '../../../components/Panel';
import { CloseRounded } from '@mui/icons-material';

interface Props {
	data: any;
	open: boolean;
	close: () => void;
	loading: boolean;
	progress: number;
	buffer: number;
	successIDs: string[];
	failedIDs: string[];
}

const SubmitedResumeDialog: React.FC<Props> = ({
	data,
	open,
	close,
	loading,
	progress,
	buffer,
	successIDs,
	failedIDs,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const handleClose = () => {
		close();
	};

	const renderThemeCloseIcon = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryDark;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const renderDialogTitle = () => (
		<Typography
			noWrap
			style={{
				color: selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
				fontSize: 20,
				fontWeight: 'bold',
				maxWidth: '90%',
			}}
		>
			{t('Autores.Resultados dos envios')}
		</Typography>
	);

	const renderGoodResults = () => (
		<Column style={{ width: '100%', alignItems: 'flex-start' }}>
			{successIDs.length > 0 && (
				<Panel
					title={t('Autores.Itens atualizados')}
					contentPadding="10px 20px"
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							gap: 15,
						}}
					>
						<Typography
							variant="caption"
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t('Autores.Sucesso para IDs')}: {successIDs.join(', ')}
						</Typography>
						<Typography variant="caption" sx={{ color: selectedTheme.success }}>
							{t('Autores.Motivo')}:
							<br />
							{data.successDetails.map((detail, index) => (
								<span key={index} style={{ marginBottom: '15px' }}>
									{detail}
									<br />
								</span>
							))}
						</Typography>
					</div>
				</Panel>
			)}
		</Column>
	);

	const renderNotGoodResults = () => (
		<Column style={{ width: '100%', alignItems: 'flex-start' }}>
			{failedIDs.length > 0 && (
				<Panel title={t('Autores.Itens falhados')} contentPadding="10px 20px">
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							gap: 15,
						}}
					>
						<Typography
							variant="caption"
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t('Autores.Falha para IDs')}: {failedIDs.join(', ')}
						</Typography>
						<Typography variant="caption" sx={{ color: selectedTheme.error }}>
							{t('Autores.Motivo')}:
							<br />
							{data.failureDetails.map((detail, index) => (
								<span key={index} style={{ marginBottom: '15px' }}>
									{detail}
									<br />
								</span>
							))}
						</Typography>
					</div>
				</Panel>
			)}
		</Column>
	);

	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			onClose={(_, reason) => {
				if (reason !== 'backdropClick') {
					handleClose();
				}
			}}
			open={open}
		>
			<DialogTitle
				style={{
					height: 60,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					color: '#FFFFFF',
					display: 'flex',
				}}
				id="max-width-dialog-title"
			>
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					{renderDialogTitle()}
					<IconButton
						onClick={handleClose}
						style={{ marginRight: '-16px' }}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<CloseRounded
							style={{
								color: renderThemeCloseIcon(),
							}}
						/>
					</IconButton>
				</Line>
			</DialogTitle>

			<DialogContent style={{ padding: 0 }}>
				<Column
					style={{
						alignItems: 'center',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
						padding: '10px 15px',
					}}
				>
					{loading ? (
						<>
							<LinearProgress
								variant="determinate"
								value={progress}
								valueBuffer={buffer}
								sx={{
									width: '100%',
									marginTop: 2,
									backgroundColor:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.disabled,
									'& .MuiLinearProgress-bar': {
										backgroundColor: selectedTheme.primaryDark,
									},
								}}
							/>
							<Typography
								variant="caption"
								sx={{
									marginTop: 2,
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{t('Autores.Progresso')}: {progress.toFixed(2)}%
							</Typography>
						</>
					) : (
						<Column style={{ width: '100%' }}>
							{renderGoodResults()}
							{renderNotGoodResults()}
						</Column>
					)}
				</Column>
			</DialogContent>

			<DialogActions
				style={{
					borderTop: 'solid',
					borderTopWidth: 1,
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.overlay3dp
							: selectedTheme.foreground,
				}}
			>
				<Line
					style={{
						justifyContent: 'end',
						margin: '2px 10px 0px 10px',
						columnGap: 20,
					}}
				>
					<Button
						size="small"
						onClick={handleClose}
						style={{
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.tone === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.primaryDark
									: selectedTheme.primaryDark,
							width: 'auto',
						}}
					>
						{t('Autores.Fechar')}
					</Button>
				</Line>
			</DialogActions>
		</Dialog>
	);
};

export default SubmitedResumeDialog;
