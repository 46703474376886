import React, { useState } from 'react';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import cep from 'cep-promise';
import convertCountryName from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import {
	TextMaskCEP,
	TextMaskDynamicRegNumber,
	TextMaskPhone,
	allCountries,
	statesUf,
} from '../../../../../helpers';
import FormDivider from '../../../../../components/FormDivider';
import { Column, Line } from '../../../../../styles';
import ConfirmAddInfo from './ConfirmAddDialog';
import {
	ClientBranchType,
	ClientType,
	CompanyType,
	CountryType,
} from '../../../../../interfaces/Clients';
import { usePO } from '../../../../../utils/POContext';
import {
	Autocomplete,
	TextField,
	Typography,
	createFilterOptions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

interface Props {
	availableClientsData: any;
	formData: ClientBranchType;
	setFormData: (ClientBranchType) => void;
	readOnly?: boolean;
	formErrors?: any;
	setFormErrors: (formErrors) => void;
	handleNewCompanyBranch: (type) => void;
}

const BasicInfo: React.FC<Props> = ({
	availableClientsData,
	formData,
	setFormData,
	readOnly,
	formErrors,
	setFormErrors,
	handleNewCompanyBranch,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const filter = createFilterOptions<any>();
	const { enqueueSnackbar } = useSnackbar();

	const [companyName, setCompanyName] = useState<CompanyType | undefined>(
		undefined
	);
	const [parentCompanyName, setParentCompanyName] = useState<
		ClientType | undefined
	>(undefined);

	const [selectedCountry, setSelectedCountry] = useState<
		CountryType | undefined
	>(undefined);

	const [addInfoDialogOpen, setAddInfoDialogOpen] = useState<boolean>(false);
	const [addType, setAddType] = useState<string>('');
	const [resetName, setResetName] = useState<number>(0);
	const [resetParent, setResetParent] = useState<number>(0);
	const [resetCountryAndState, setResetCountryAndState] = useState<number>(0);

	convertCountryName.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/pt.json')
	);

	const useStyles = makeStyles(() => ({
		autoCompleteStyle: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		noOptions: {
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
		},
	}));

	const classes = useStyles();

	// Get Info from CEP
	const handleCepChange = async (event) => {
		const cepValue = event.target.value.replace(/[^0-9]/g, '');
		if (cepValue.length === 8) {
			try {
				const address = await cep(cepValue);
				if (address.state) {
					const state = statesUf.find((el) => el.uf === address.state);
					setFormData({
						...formData,
						streetAddress: address.street,
						cityName: address.city,
						stateName: state ? state.name : '',
						zipCode: address.cep,
					});
					setSelectedCountry({ name: 'Brasil' });
				}
			} catch (error) {
				enqueueSnackbar(t('Clientes.CEP não encontrado'), {
					variant: 'warning',
				});
			}
		}
	};

	const handleNewClient = () => {
		setAddType('newClient');
		setTimeout(() => {
			setAddInfoDialogOpen(true);
		}, 100);
		setTimeout(() => {
			handleNewCompanyBranch(null);
		}, 100);
	};

	const handleAddClientBranch = (client: any) => {
		setAddType('branch');
		setAddInfoDialogOpen(true);
		setTimeout(() => {
			handleNewCompanyBranch(client.id);
		}, 100);
	};

	const handleCloseDialog = () => {
		setResetName(resetName + 1);
		setResetParent(resetParent + 1);
		setResetCountryAndState(resetCountryAndState + 1);
		setFormErrors({});
		setCompanyName({ name: '' });
		handleNewCompanyBranch(null);
		setAddInfoDialogOpen(false);
	};

	const handleConfirmDialog = (type: string) => {
		setAddType(type);
		setAddInfoDialogOpen(false);
	};

	const checkClientRegNumberType = (event) => {
		if (event.target.value.length === 14) {
			setFormData({
				...formData,
				clientRegNumber: 'CPF',
			});
		}
		if (event.target.value.length === 18) {
			setFormData({
				...formData,
				clientRegNumber: 'CNPJ',
			});
		}
		return '';
	};

	const locationForm = () => (
		<>
			<Line
				style={{
					marginTop: 30,
				}}
			>
				<TextField
					variant="outlined"
					size="small"
					name="cityName"
					label={t('Clientes.Cidade')}
					value={formData?.cityName}
					onChange={(event) => {
						handleCepChange(event);
						setFormData({ ...formData, cityName: event.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '60%',
						marginRight: '20px',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
				<TextField
					variant="outlined"
					size="small"
					name="stateName"
					label={t('Clientes.Estado / Província')}
					value={formData?.stateName}
					onChange={(event) => {
						handleCepChange(event);
						setFormData({ ...formData, stateName: event.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' ? selectedTheme?.textColorHigh : '',
						},
					}}
					sx={{
						width: '40%',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
			<Line
				style={{
					marginTop: 20,
				}}
			>
				<TextField
					variant="outlined"
					size="small"
					name="zipCode"
					label={t('Clientes.Código Postal')}
					value={formData?.zipCode}
					onChange={handleCepChange}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						inputComponent: TextMaskCEP as any,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '25%',
						marginRight: '20px',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>

				<TextField
					variant="outlined"
					size="small"
					name="streetAddress"
					label={t('Clientes.Logradouro')}
					value={formData?.streetAddress}
					onChange={(event) => {
						handleCepChange(event);
						setFormData({ ...formData, streetAddress: event.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '75%',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
			<Line
				style={{
					marginTop: 20,
				}}
			>
				<TextField
					variant="outlined"
					size="small"
					name="numberAddress"
					label={t('Clientes.Número')}
					value={formData?.numberAddress}
					onChange={(event) => {
						setFormData({ ...formData, numberAddress: event.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '25%',
						marginRight: '20px',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>

				<TextField
					variant="outlined"
					size="small"
					name="complement"
					label={t('Clientes.Complemento')}
					value={formData?.complement}
					onChange={(e) => {
						setFormData({ ...formData, complement: e.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '75%',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
			<Line
				style={{
					marginTop: 20,
				}}
			>
				<TextField
					variant="outlined"
					size="small"
					name="complement"
					label={t('Clientes.Relatório Superset')}
					value={formData?.BIID}
					onChange={(e) => {
						setFormData({ ...formData, BIID: e.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '100%',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
		</>
	);
	return (
		<>
			<Column>
				<FormDivider
					name={t('Clientes.Pai')}
					background={
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground
					}
				/>
				{readOnly ? (
					<TextField
						variant="outlined"
						size="small"
						name="name"
						label={t('Clientes.Pai')}
						value={formData.parent}
						autoComplete="off"
						InputLabelProps={{
							shrink: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						InputProps={{
							readOnly: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
						}}
						sx={{
							width: '100%',
							'.MuiFormHelperText-root.Mui-error': {
								mt: '0px',
								color: selectedTheme.error,
								borderColor: selectedTheme.error,
								position: 'absolute',
								bottom: '-21px',
							},
							'& .MuiInputBase-root': {
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
						}}
					/>
				) : (
					<Autocomplete
						key={`parentInput-${resetParent}`}
						value={parentCompanyName}
						onChange={(_, newValue) => {
							setParentCompanyName(newValue);
							setFormData({
								...formData,
								parent: newValue?.id || '',
							});
						}}
						classes={{
							option: classes.autoCompleteStyle,
							paper: classes.autoCompleteStyle,
							noOptions: classes.noOptions,
						}}
						handleHomeEndKeys
						disableClearable
						id="clientsParentField"
						noOptionsText={t('Clientes.Nenhuma opção disponível')}
						options={availableClientsData.filter(
							(client) => client.name !== formData.name
						)}
						getOptionLabel={(option) => option.name}
						renderOption={(props, option) => <li {...props}>{option.name}</li>}
						isOptionEqualToValue={(
							option: ClientType,
							value: ClientType | undefined
						) => option.id === value?.id}
						style={{ width: '100%', marginBottom: 10 }}
						renderInput={(params) => (
							<TextField
								{...params}
								name="parent"
								label={t('Clientes.Pai')}
								size="small"
								value={formData?.parent}
								placeholder={t(
									'Clientes.Escolha uma empresa da lista para adicionar como pai'
								)}
								variant="outlined"
								onChange={(e) => {
									setFormData({ ...formData, parent: e.target.value });
								}}
								InputLabelProps={{
									shrink: true,
									style: {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									},
								}}
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password', // disable autocomplete and autofill
									style: {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorMedium
												: '',
									},
								}}
								sx={{
									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
						)}
					/>
				)}
				<FormDivider
					name={t('Clientes.Nome')}
					background={
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground
					}
				/>
				{readOnly ? (
					<TextField
						variant="outlined"
						size="small"
						name="name"
						label={t('Clientes.Nome')}
						value={formData.name}
						error={!!formErrors?.name}
						helperText={formErrors?.name}
						autoComplete="off"
						InputLabelProps={{
							shrink: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						InputProps={{
							readOnly: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
						}}
						sx={{
							width: '100%',
							'.MuiFormHelperText-root.Mui-error': {
								mt: '0px',
								color: selectedTheme.error,
								borderColor: selectedTheme.error,
								position: 'absolute',
								bottom: '-21px',
							},
							'& .MuiInputBase-root': {
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
						}}
					/>
				) : (
					<Autocomplete
						key={`companyInput-${resetName}`}
						value={companyName}
						onChange={(_, newValue) => {
							if (typeof newValue === 'string') {
								setCompanyName({ name: newValue });
								setFormData({ ...formData, name: newValue });
								handleNewClient();
							} else if (newValue?.inputValue) {
								setCompanyName({ name: newValue.inputValue });
								setFormData({ ...formData, name: newValue.inputValue });
								handleNewClient();
							} else if (newValue?.name) {
								setCompanyName(newValue);
								setFormData({ ...formData, name: newValue.name });
								handleAddClientBranch(newValue);
							}
						}}
						classes={{
							option: classes.autoCompleteStyle,
							paper: classes.autoCompleteStyle,
						}}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);

							const parent = formData.parent;
							const filteredOptions = filtered.filter(
								(option) => option.name !== parent
							);

							if (params.inputValue !== '') {
								filteredOptions.push({
									inputValue: params.inputValue,
									name: `${t('Clientes.Adicionar novo cliente')}: "${params.inputValue}"`,
								});
							}

							return filteredOptions;
						}}
						handleHomeEndKeys
						disableClearable
						id="clientsField"
						options={availableClientsData.filter(
							(client) =>
								client.name !== formData.name && client.id !== formData.parent
						)}
						getOptionLabel={(option) => {
							// Value selected with enter key, right from the input
							if (typeof option === 'string') {
								return option;
							}
							// Add "new Client" option created dynamically
							if (option.inputValue) {
								return option.inputValue;
							}
							// Regular option
							return option.name;
						}}
						renderOption={(props, option) => (
							<li {...props}>{option.name as string}</li>
						)}
						isOptionEqualToValue={(
							option: CompanyType,
							value: CompanyType | undefined
						) => option.name === value?.name}
						style={{ width: '100%', marginBottom: 10 }}
						freeSolo
						renderInput={(params) => (
							<TextField
								key={formErrors.name}
								{...params}
								name="name"
								label={t('Clientes.Nome')}
								size="small"
								value={formData?.name || ''}
								error={!!formErrors?.name}
								helperText={formErrors?.name}
								placeholder={t(
									'Clientes.Digite o nome de um novo cliente ou escolha uma empresa da lista para adicionar uma filial'
								)}
								variant="outlined"
								onChange={(e) => {
									setFormData({ ...formData, name: e.target.value });
								}}
								InputLabelProps={{
									shrink: true,
									style: {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									},
								}}
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password', // disable autocomplete and autofill
									style: {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorMedium
												: '',
									},
								}}
								sx={{
									'.MuiFormHelperText-root.Mui-error': {
										mt: '0px',
										color: selectedTheme.error,
										borderColor: selectedTheme.error,
										position: 'absolute',
										bottom: '-21px',
									},
									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
						)}
					/>
				)}
				<FormDivider
					name={t('Clientes.Dados da Unidade')}
					background={
						selectedTheme.id === 'dark'
							? selectedTheme.overlay6dp
							: selectedTheme.foreground
					}
				/>
				<>
					<Line style={{ alignItems: 'flex-start' }}>
						<Autocomplete
							id="countrySelect"
							style={{ width: '60%', marginRight: 20 }}
							value={selectedCountry || undefined}
							options={allCountries}
							onChange={(event, newValue) => {
								setSelectedCountry(newValue);
								setFormData({
									...formData,
									countryID: newValue?.name,
								});
							}}
							classes={{
								option: classes.autoCompleteStyle,
								paper: classes.autoCompleteStyle,
								noOptions: classes.noOptions,
							}}
							noOptionsText={t('Clientes.Nenhuma opção disponível')}
							autoComplete
							autoSelect
							disableClearable
							getOptionLabel={(option) => option.name}
							renderOption={(props, option: CountryType) => (
								<li {...props}>{option.name}</li>
							)}
							isOptionEqualToValue={(
								option: CountryType,
								value: CountryType | undefined
							) => option.name === value?.name}
							renderInput={(params) => (
								<TextField
									key={formErrors.countryID}
									{...params}
									name="countryID"
									size="small"
									label={t('Clientes.País')}
									value={selectedCountry}
									error={!!formErrors.countryID}
									helperText={formErrors.countryID || ''}
									variant="outlined"
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
										style: {
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorHigh,
										},
									}}
									InputLabelProps={{
										shrink: true,
										style: {
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme.textColorMedium,
										},
									}}
									sx={{
										'.MuiFormHelperText-root.Mui-error': {
											mt: '0px',
											color: selectedTheme.error,
											borderColor: selectedTheme.error,
											position: 'absolute',
											bottom: '-21px',
										},
										'& .MuiInputBase-root': {
											background:
												selectedTheme.id === 'dark' &&
												selectedTheme?.overlay3dp,
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
											},
											':hover': {
												'& > fieldset': {
													borderColor:
														selectedTheme.id === 'dark' ? '#fff' : '#000',
												},
											},
										},
										'.MuiSvgIcon-root ': {
											fill:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorMedium,
										},
										'& .MuiInputBase-root.Mui-disabled': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
											},
										},
									}}
								/>
							)}
						/>

						<TextField
							key={formErrors.clientRegNumber}
							variant="outlined"
							size="small"
							name="clientRegNumber"
							value={formData?.clientRegNumber}
							error={!!formErrors?.clientRegNumber}
							helperText={formErrors?.clientRegNumber || ''}
							label={t('Clientes.Número de registro / CNPJ')}
							autoComplete="off"
							onChange={(event) => {
								const newClientRegNumber = event.target.value;
								checkClientRegNumberType(event);
								setFormData({
									...formData,
									clientRegNumber: newClientRegNumber,
								});
							}}
							InputLabelProps={{
								shrink: true,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							InputProps={{
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorHigh
											: '',
								},
								inputComponent: TextMaskDynamicRegNumber as any,
								endAdornment: (
									<Typography
										variant="caption"
										style={{
											color:
												cnpj.isValid(formData.clientRegNumber) ||
												cpf.isValid(formData.clientRegNumber)
													? selectedTheme.success
													: selectedTheme.error,
											marginBottom: -2,
										}}
									>
										{formData.clientRegNumber?.toUpperCase() || ''}
									</Typography>
								),
							}}
							sx={{
								width: '40%',
								'.MuiFormHelperText-root.Mui-error': {
									mt: '0px',
									color: selectedTheme.error,
									borderColor: selectedTheme.error,
									position: 'absolute',
									bottom: '-21px',
								},
								'& .MuiInputBase-root': {
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
							}}
						/>
					</Line>
					{locationForm()}
					<FormDivider
						name={t('Clientes.Informações de Contato')}
						background={
							selectedTheme.id === 'dark'
								? selectedTheme.overlay6dp
								: selectedTheme.foreground
						}
					/>
					<TextField
						key={formErrors.contactName}
						variant="outlined"
						size="small"
						name="contactName"
						label={t('Clientes.Nome do contato')}
						value={formData?.contactName}
						error={!!formErrors.contactName}
						helperText={formErrors.contactName || ''}
						onChange={(event) => {
							setFormData({ ...formData, contactName: event.target.value });
						}}
						autoComplete="off"
						InputLabelProps={{
							shrink: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						InputProps={{
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorHigh
										: '',
							},
						}}
						sx={{
							width: '100%',
							'.MuiFormHelperText-root.Mui-error': {
								mt: '0px',
								color: selectedTheme.error,
								borderColor: selectedTheme.error,
								position: 'absolute',
								bottom: '-21px',
							},
							'& .MuiInputBase-root': {
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
						}}
					/>
					<Line style={{ marginTop: 30, marginBottom: 10 }}>
						<TextField
							key={formErrors.email1}
							variant="outlined"
							size="small"
							name="email1"
							label={t('Clientes.E-mail 1')}
							value={formData?.email1}
							error={!!formErrors.email1}
							helperText={formErrors.email1 || ''}
							onChange={(event) => {
								setFormData({ ...formData, email1: event.target.value });
							}}
							autoComplete="off"
							InputLabelProps={{
								shrink: true,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							InputProps={{
								style: {
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								},
							}}
							sx={{
								width: '70%',
								marginRight: '20px',
								'.MuiFormHelperText-root.Mui-error': {
									mt: '0px',
									color: selectedTheme.error,
									borderColor: selectedTheme.error,
									position: 'absolute',
									bottom: '-21px',
								},
								'& .MuiInputBase-root': {
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
							}}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="phone1"
							label={t('Clientes.Telefone 1')}
							value={formData?.phone1}
							onChange={(e) => {
								setFormData({ ...formData, phone1: e.target.value });
							}}
							autoComplete="off"
							InputLabelProps={{
								shrink: true,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							InputProps={{
								inputComponent: TextMaskPhone as any,
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorHigh
											: '',
								},
							}}
							sx={{
								width: '30%',
								'& .MuiInputBase-root': {
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
							}}
						/>
					</Line>
					<Line style={{ marginTop: 20, marginBottom: 10 }}>
						<TextField
							variant="outlined"
							size="small"
							name="email2"
							label={t('Clientes.E-mail 2')}
							value={formData?.email2}
							error={!!formErrors.email2}
							helperText={formErrors.email2 || ''}
							onChange={(e) => {
								setFormData({ ...formData, email2: e.target.value });
							}}
							autoComplete="off"
							InputLabelProps={{
								shrink: true,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							InputProps={{
								style: {
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								},
							}}
							sx={{
								width: '70%',
								marginRight: '20px',
								'.MuiFormHelperText-root.Mui-error': {
									mt: '0px',
									color: selectedTheme.error,
									borderColor: selectedTheme.error,
									position: 'absolute',
									bottom: '-21px',
								},
								'& .MuiInputBase-root': {
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
							}}
						/>
						<TextField
							variant="outlined"
							size="small"
							name="phone2"
							label={t('Clientes.Telefone 2')}
							autoComplete="off"
							value={formData?.phone2}
							onChange={(e) => {
								setFormData({ ...formData, phone2: e.target.value });
							}}
							InputLabelProps={{
								shrink: true,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							InputProps={{
								inputComponent: TextMaskPhone as any,
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorHigh
											: '',
								},
							}}
							sx={{
								width: '30%',
								'& .MuiInputBase-root': {
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
							}}
						/>
					</Line>
					<FormDivider
						name={t('Clientes.Descrição')}
						background={
							selectedTheme.id === 'dark'
								? selectedTheme.overlay6dp
								: selectedTheme.foreground
						}
					/>
					<TextField
						variant="outlined"
						multiline
						rows="2"
						name="description"
						autoComplete="off"
						value={formData?.description}
						onChange={(e) => {
							setFormData &&
								setFormData({ ...formData, description: e.target.value });
						}}
						InputProps={{
							readOnly,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
						}}
						sx={{
							width: '100%',
							'& .MuiInputBase-root': {
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
						}}
					/>
				</>
			</Column>
			<ConfirmAddInfo
				setConfirm={() => handleConfirmDialog(addType)}
				closeDialog={handleCloseDialog}
				clientID={companyName?.name ?? ''}
				open={addInfoDialogOpen}
				addType={addType}
			/>
			<TextField
				name="addType"
				value={addType}
				style={{ display: 'none' }}
				variant={'outlined'}
			/>
		</>
	);
};
export default BasicInfo;
