import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Typography,
	Zoom,
} from '@mui/material';
import { ReportProblem, ThumbUpRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../components/LoadingDots';
import { api, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';

import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { OfferToolTip } from '../../../../helpers';
import {
	updateGoodCards,
	updateUnderInvestigationCard,
} from '../../../../redux/slices/cardFeedbackSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
	dialogPaper: {
		maxHeight: '90vh',
	},
}));

interface Props {
	author: any;
}

const DenounceConfirmationDialog: React.FC<Props> = ({ author }: Props) => {
	const { token } = useAuth0();
	const { t } = useTranslation();
	const { selectedTheme, selectedClient } = usePO();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	setupRequestToken(api, token);
	const dispatch = useDispatch();

	const goodCardsList = useSelector(
		(state: any) => state.cardFeedback.goodCardsList
	);
	const underInvestigationCardList = useSelector(
		(state: any) => state.cardFeedback.underInvestigationCardList
	);

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [operationType, setOperationType] = useState('NOT_SET');

	const checksDataInRedux = (list, data) => {
		return list?.includes(data);
	};

	const handleClickPermit = () => {
		setOperationType('official');
		setOpen(true);
	};

	const handleClickInvestigate = () => {
		setOperationType('under_investigation');
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			setOperationType('NOT_SET');
		}, 300);
	};

	const markAsOfficial = async () => {
		setLoading(true);
		try {
			const response = await api.patch(
				`/authors/${author.authorID}/official?official=${!checksDataInRedux(
					goodCardsList,
					author.authorID
				)}`
			);

			if (!checksDataInRedux(goodCardsList, author.authorID)) {
				dispatch(updateGoodCards([...goodCardsList, author.authorID]));
			} else {
				dispatch(
					updateGoodCards(goodCardsList.filter((el) => el !== author?.authorID))
				);
			}
			enqueueSnackbar(t(response.data.detail), { variant: 'success' });
		} catch (error: any) {
			if (error.response?.status === 404) {
				enqueueSnackbar(t(error.response.data.detail), { variant: 'error' });
			} else if (error.response?.status >= 400) {
				enqueueSnackbar(t(error.response.data.detail || 'An error occurred'), {
					variant: 'error',
				});
			} else {
				enqueueSnackbar(t('An unexpected error occurred'), {
					variant: 'error',
				});
			}
		} finally {
			setLoading(false);
			handleClose();
		}
	};

	const markAsUnderInvestigation = async () => {
		setLoading(true);
		try {
			const response = await api.patch(
				`/authors/${
					author.authorID
				}/under_investigation?underInvestigation=${!checksDataInRedux(
					underInvestigationCardList,
					author.authorID
				)}`
			);

			if (!checksDataInRedux(underInvestigationCardList, author.authorID)) {
				dispatch(
					updateUnderInvestigationCard([
						...underInvestigationCardList,
						author.authorID,
					])
				);
			} else {
				dispatch(
					updateUnderInvestigationCard(
						underInvestigationCardList.filter((el) => el !== author?.authorID)
					)
				);
			}
			enqueueSnackbar(t(response.data.detail), { variant: 'success' });
		} catch (error: any) {
			if (error.response?.status === 404) {
				enqueueSnackbar(t(error.response.data.detail), { variant: 'error' });
			} else if (error.response?.status >= 400) {
				enqueueSnackbar(t(error.response.data.detail || 'An error occurred'), {
					variant: 'error',
				});
			} else {
				enqueueSnackbar(t('An unexpected error occurred'), {
					variant: 'error',
				});
			}
		} finally {
			setLoading(false);
			handleClose();
		}
	};

	const handleSave = () => {
		switch (operationType) {
			case 'official':
				markAsOfficial();
				break;
			case 'under_investigation':
				markAsUnderInvestigation();
				break;
			default:
				break;
		}
	};

	const renderActionMessage = () => {
		if (selectedClient) {
			if (operationType === 'official')
				return (
					<Typography variant="h6" data-testid="officialAction">
						{checksDataInRedux(goodCardsList, author.authorID) ||
						author.authorData.official?.value
							? t('Autores.Remover o autor da lista de permitidos de')
							: t('Autores.Classificar o autor como permitido para o cliente')}
						: {selectedClient?.name}?
					</Typography>
				);
		}
		if (operationType === 'under_investigation')
			return (
				<Typography variant="h6" data-testid="underInvestigationAction">
					{checksDataInRedux(underInvestigationCardList, author.authorID) ||
					author.authorData.official?.value
						? t('Autores.Remover o autor da lista de investigados de')
						: t('Autores.Classificar o autor como investigado pelo cliente')}
					: {selectedClient?.name}?
				</Typography>
			);
	};

	const handleFooter = () => {
		return (
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					size="small"
					onClick={handleClose}
					sx={{
						color: loading ? 'gray' : selectedTheme.error,
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					{t('Autores.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						size="small"
						sx={{
							color:
								selectedTheme?.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
						onClick={handleSave}
					>
						{checksDataInRedux(goodCardsList, author.authorID) ||
						checksDataInRedux(underInvestigationCardList, author.authorID) ||
						author.authorData.official?.value
							? `${t('Autores.Remover')}`
							: `${t('Autores.Classificar')}`}
					</Button>
				)}
			</Line>
		);
	};

	const renderFooter = () => {
		return (
			<Line>
				<div
					style={{
						display: checksDataInRedux(
							underInvestigationCardList,
							author.authorID
						)
							? 'none'
							: 'block',
					}}
				>
					<OfferToolTip
						title={
							checksDataInRedux(goodCardsList, author.authorID) ||
							author.authorData.official?.value
								? `${t('Autores.Remover da lista de permitidos')}`
								: `${t('Autores.Permitir')}`
						}
						aria-label="inativo"
						arrow
						enterDelay={500}
						enterNextDelay={500}
						TransitionComponent={Zoom}
					>
						<IconButton
							sx={{
								padding: '8px',
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
							onClick={handleClickPermit}
						>
							<ThumbUpRounded
								style={{
									margin: 2,
									fontSize: 20,
									color: selectedTheme.success,
								}}
							/>
						</IconButton>
					</OfferToolTip>
				</div>
				<div
					style={{
						display: checksDataInRedux(goodCardsList, author.authorID)
							? 'none'
							: 'block',
					}}
				>
					<OfferToolTip
						title={
							checksDataInRedux(underInvestigationCardList, author.authorID) ||
							author.authorData.official?.value
								? `${t('Autores.Remover da lista de investigados')}`
								: `${t('Autores.Classificar como investigado')}`
						}
						aria-label="inativo"
						arrow
						enterDelay={500}
						enterNextDelay={500}
						TransitionComponent={Zoom}
					>
						<IconButton
							sx={{
								padding: '8px',
								marginRight: '-5px',
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
							onClick={handleClickInvestigate}
						>
							<ReportProblem
								style={{
									margin: 2,
									fontSize: 21,
									color: selectedTheme.warning,
								}}
							/>
						</IconButton>
					</OfferToolTip>
				</div>
			</Line>
		);
	};

	const renderContent = () => (
		<DialogContent
			style={{
				padding: '20px 30px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay6dp
						: selectedTheme.foreground,
				color: selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
			}}
		>
			<Line style={{ justifyContent: 'center' }}>{renderActionMessage()}</Line>
		</DialogContent>
	);

	return (
		<div>
			{renderFooter()}
			<Dialog
				open={open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				classes={{ paper: classes.dialogPaper }}
			>
				{renderContent()}
				<DialogActions
					style={{
						borderTop: 'solid',
						borderTopWidth: 1,
						borderTopColor:
							selectedTheme.id === 'dark'
								? selectedTheme?.footerLine
								: '#eaeaea',
						padding: '10px 15px',
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.overlay3dp
								: selectedTheme.foreground,
					}}
				>
					{handleFooter()}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default DenounceConfirmationDialog;
