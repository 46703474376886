import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../../utils/POContext';
import { Line } from '../../../../styles';
import LoadingDots from '../../../../components/LoadingDots';
import { toggleDomain } from '../../../../services/domains';
import { enqueueSnackbar } from 'notistack';
import { Item } from '../../../../interfaces/Domains';

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
	data: Item | null;
	onConfirm: (status: boolean) => void;
}

const MonitoringToggleDialog: React.FC<Props> = ({
	open,
	setOpen,
	data,
	onConfirm,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();

	const [loading, setLoading] = useState(false);

	const handleConfirm = async () => {
		if (!data) return;
		const domainID = data.id;
		const switchRuleTo = !data.ISactive;

		const payload = {
			url: data.url,
			actions: data.actions,
			eventDate: data.eventDate,
			ISactive: switchRuleTo,
			logs: data.logs || [{}],
			ISonline: data.ISonline,
			searchID: data.searchID,
			clientID: data.clientID,
		};
		setLoading(true);
		const response = await toggleDomain(domainID, switchRuleTo, payload);
		if (response && response.status !== undefined) {
			if (response.status < 300) {
				enqueueSnackbar(t(`Buscas.Tabs.Monitoramento.${response.message}`), {
					variant: 'success',
				});
			} else if (response.status >= 400) {
				const message = response.message;
				enqueueSnackbar(t(`Buscas.Tabs.Monitoramento.${message}`), {
					variant: 'error',
				});
			}
		}
		setOpen(false);
		setLoading(false);
		onConfirm(true);
	};

	return (
		<Dialog open={open}>
			<DialogContent
				style={{
					padding: 22,
					width: '100%',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
				}}
			>
				<Typography
					align="center"
					variant="h6"
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					}}
				>
					{data?.ISactive
						? `${t('Buscas.Tabs.Monitoramento.Desativar o monitoramento')}: ${data?.searchID}`
						: `${t('Buscas.Tabs.Monitoramento.Ativar o monitoramento')}: ${data?.searchID}`}
					?
				</Typography>
			</DialogContent>
			<DialogActions
				style={{
					borderTop: 'solid',
					borderTopWidth: 1,
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
				}}
			>
				<Line
					style={{
						justifyContent: 'space-between',
						padding: 5,
					}}
				>
					<Button
						disabled={loading}
						onClick={() => setOpen(false)}
						style={{ color: selectedTheme.error }}
					>
						{t('Buscas.Tabs.Monitoramento.Cancelar')}
					</Button>
					<Button
						disabled={loading}
						onClick={handleConfirm}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
							background:
								selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: selectedTheme.foreground
							}`,
						}}
					>
						{loading ? (
							<LoadingDots height={25} width={60} loop />
						) : (
							t('Buscas.Tabs.Monitoramento.Confirmar')
						)}
					</Button>
				</Line>
			</DialogActions>
		</Dialog>
	);
};

export default MonitoringToggleDialog;
