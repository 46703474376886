import { ChevronRightRounded, KeyboardBackspace } from '@mui/icons-material';
import {
	Typography,
	IconButton,
	Divider,
	InputAdornment,
	TextField,
	Slide,
	Autocomplete,
	Button,
	CircularProgress,
	Popper,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Column, Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import FormDivider from '../../../../components/FormDivider';
import { useSelector } from 'react-redux';
import { searchesFilters } from '../../../../services/searches/searchesFilters';
import { enqueueSnackbar } from 'notistack';
import LoadingDots from '../../../../components/LoadingDots';
import { fetchAvailablePlatforms } from '../../../../services/searches/searchesFilters';
import { OfferToolTip } from '../../../../helpers';

interface Filters {
	titleToSearch: string;
	termsToSearch: string;
	selectedStartDate: null | string;
	selectedFinalDate: null | string;
	platformINcountryID: null | string;
}

interface Props {
	buildTableData: (data: any, totalHits?: number) => void;
	currentPage: number;
	isFilterBarOpen: boolean;
	loading: boolean;
	refreshTable: number;
	rowsPerPage: number;
	searchText: string;
	setIsFilterBarOpen: (value: boolean) => void;
	setLoading: (value: boolean) => void;
}

const SearchFilterBar: React.FC<Props> = ({
	buildTableData,
	currentPage,
	isFilterBarOpen,
	loading,
	refreshTable,
	rowsPerPage,
	searchText,
	setIsFilterBarOpen,
	setLoading,
}) => {
	const { selectedTheme, selectedClient } = usePO();
	const vertMenuState = useSelector((state: any) => state.vertMenu);
	const drawerWidth = window.innerHeight < 901 ? 60 : 70;
	const drawerWidthOpen = window.innerHeight < 901 ? 200 : 230;

	const [mouseOver, setMouseOver] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState('defaultFilters');
	const [loadingPlatforms, setLoadingPlatforms] = useState<boolean>(false);
	const [availablePlatforms, setAvailablePlatforms] = useState<any[]>([]);
	const [selectedPlatform, setSelectedPlatform] = useState<any>(null);

	const [selectedCountry, setSelectedCountry] = useState<any>(null);

	const [filters, setFilters] = useState<Filters>({
		titleToSearch: '',
		termsToSearch: '',
		selectedStartDate: null,
		selectedFinalDate: null,
		platformINcountryID: null,
	});

	const handleClearFilters = () => {
		const resetFilters = {
			titleToSearch: '',
			termsToSearch: '',
			selectedStartDate: null,
			selectedFinalDate: null,
			platformINcountryID: null,
		};

		setFilters(resetFilters);
		setSelectedFilter('defaultFilters');
		setSelectedPlatform(null);
		setSelectedCountry(null);
		sendSearchQuery(resetFilters);
	};

	const updateFilters = (keyOrUpdates, value) => {
		setFilters((prevFilters) => {
			if (typeof keyOrUpdates === 'string') {
				return {
					...prevFilters,
					[keyOrUpdates]: value,
				};
			} else if (typeof keyOrUpdates === 'object') {
				return {
					...prevFilters,
					...keyOrUpdates,
				};
			}
			return prevFilters;
		});
	};

	const handleSelectPlatform = (value) => {
		setSelectedPlatform(value);
		setSelectedCountry(null);
	};

	const handleSelectPlatformINcountryID = (value) => {
		const platformName = selectedPlatform.name.replace(/\s+/g, '');
		const combinedID = `${platformName}|${value.id}`;

		setSelectedCountry(value);
		setFilters((prev) => ({
			...prev,
			platformINcountryID: combinedID,
		}));

		sendSearchQuery({
			...filters,
			platformINcountryID: combinedID,
		});
	};

	const sendSearchQuery = async (customFilters = filters) => {
		setLoading(true);
		const response = await searchesFilters(
			queryParams(customFilters),
			selectedClient.id
		);
		if (response?.success) {
			buildTableData(response.data.hits, response.data.totalHits);
		} else {
			enqueueSnackbar(t('Buscas.Tabs.Buscas Salvas.Sem resultados'), {
				variant: 'warning',
			});
			buildTableData([]);
		}
		setLoading(false);
	};

	const queryParams = (customFilters = filters) => ({
		title: customFilters.titleToSearch,
		terms: customFilters.termsToSearch,
		platformINcountryID: customFilters.platformINcountryID,
		startDate: customFilters.selectedStartDate
			? `${customFilters.selectedStartDate}T00:00:00`
			: null,
		finalDate: customFilters.selectedFinalDate
			? `${customFilters.selectedFinalDate}T23:59:59`
			: null,
		itemsPerPage: rowsPerPage,
		page: currentPage + 1,
	});

	const handleCloseOnBlur = () => {
		if (!mouseOver) {
			setIsFilterBarOpen(false);
		}
	};

	const getAvailablePlatforms = async () => {
		setLoadingPlatforms(true);
		const response: any = await fetchAvailablePlatforms();
		if (response?.success) {
			setAvailablePlatforms(Object.values(response.data));
		}
		setLoadingPlatforms(false);
	};

	useEffect(() => {
		if (selectedClient?.id !== undefined || selectedFilter !== 'defaultFilters')
			sendSearchQuery();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedClient,
		searchText,
		currentPage,
		refreshTable,
		rowsPerPage,
		selectedFilter,
	]);

	const useStyles = makeStyles(() => ({
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		renderOption: {
			'&.MuiAutocomplete-option': {
				'&:hover': {
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme?.foreground,
				},
			},
		},
		noOptions: {
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
		},
	}));

	const classes = useStyles();

	const CustomPopper = styled(Popper)({
		'& .MuiAutocomplete-loading': {
			color: selectedTheme?.id === 'dark' && selectedTheme?.textColorMedium,
		},
	});

	const renderFilterBarHeader = () => (
		<Line
			style={{
				width: '100%',
				justifyContent: 'space-between',
				marginRight: -4,
			}}
		>
			<Typography
				variant="subtitle2"
				style={{
					fontWeight: 'bold',
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: '#4F4F4F',
				}}
			>
				{t('FilterBar.Filtros avançados')}:
			</Typography>
			<IconButton
				onClick={() => setIsFilterBarOpen(false)}
				data-testid="close-filter-bar-button"
				size="small"
				style={{ marginRight: -5 }}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<KeyboardBackspace
					style={{
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: '#4F4F4F',
					}}
				/>
			</IconButton>
		</Line>
	);

	const renderPublicationFilters = () => (
		<>
			<FormDivider
				name={t('FilterBar.Nome')}
				margin="0 0 6px 0"
				opacity={0.8}
				background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
			/>
			<TextField
				data-testid="titleToSearch"
				variant="outlined"
				size="small"
				value={filters.titleToSearch || ''}
				placeholder={t('FilterBar.Título')}
				onChange={(event) => {
					updateFilters('titleToSearch', event.target.value);
				}}
				onKeyDown={(event) => {
					if (event.keyCode === 13) {
						sendSearchQuery();
					}
				}}
				sx={{
					width: '100%',
					height: '40px',
					borderRadius: '4px',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay3dp
							: selectedTheme.foreground,
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
				InputProps={{
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					},
					endAdornment: (
						<InputAdornment position="end" style={{ marginRight: -12 }}>
							<Divider
								style={{
									height: 28,
									background:
										selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
								}}
								orientation="vertical"
							/>
							<IconButton
								size="small"
								onClick={() => sendSearchQuery()}
								style={{
									padding: 7,
									borderRadius: '0px 5px 5px 0px',
								}}
								sx={{
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
							>
								<ChevronRightRounded
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									}}
								/>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		</>
	);

	const renderSearcTermsFilters = () => (
		<>
			<FormDivider
				name={t('FilterBar.Buscar Termos')}
				margin="10px 0 7px 0"
				opacity={0.8}
				background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
			/>
			<TextField
				data-testid="termsToSearch"
				variant="outlined"
				size="small"
				value={filters.termsToSearch || ''}
				placeholder={t('FilterBar.Termos')}
				onChange={(event) => {
					updateFilters('termsToSearch', event.target.value);
				}}
				onKeyDown={(event) => {
					if (event.keyCode === 13) {
						sendSearchQuery();
					}
				}}
				sx={{
					width: '100%',
					height: '40px',
					borderRadius: '4px',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay3dp
							: selectedTheme.foreground,
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
				InputProps={{
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					},
					endAdornment: (
						<InputAdornment position="end" style={{ marginRight: -12 }}>
							<Divider
								style={{
									height: 28,
									background:
										selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
								}}
								orientation="vertical"
							/>
							<IconButton
								size="small"
								onClick={() => sendSearchQuery()}
								style={{
									padding: 7,
									borderRadius: '0px 5px 5px 0px',
								}}
								sx={{
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
							>
								<ChevronRightRounded
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									}}
								/>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		</>
	);

	const renderPlatformFilters = () => (
		<>
			<FormDivider
				name={t('FilterBar.Plataforma')}
				margin="10px 0 7px 0"
				opacity={0.8}
				background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
			/>
			<Autocomplete
				id="platform-autocomplete"
				data-testid="platform-autocomplete"
				style={{
					width: 310,
					background: 'white',
					borderRadius: 4,
					borderColor: 'transparent',
				}}
				noOptionsText={t('FilterBar.Nenhuma opção disponível')}
				classes={{
					paper: classes.option,
					noOptions: classes.noOptions,
				}}
				onOpen={async () => {
					if (availablePlatforms.length === 0) {
						await getAvailablePlatforms();
					}
				}}
				value={selectedPlatform}
				onChange={(event, newValue) => {
					if (newValue === null) {
						setSelectedPlatform(null);
						setSelectedCountry(null);
						setFilters((prev) => ({
							...prev,
							platformINcountryID: null,
						}));
						sendSearchQuery({
							...filters,
							platformINcountryID: null,
						});
					}
					return handleSelectPlatform(newValue);
				}}
				isOptionEqualToValue={(option, value) => {
					return value ? option.name === value.name : false;
				}}
				getOptionLabel={(option) => option.name}
				size="small"
				options={availablePlatforms}
				loading={loadingPlatforms}
				loadingText={t('FilterBar.Carregando...')}
				PopperComponent={CustomPopper}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={
							availablePlatforms.length === 0
								? t('FilterBar.Todas as plataformas')
								: t('FilterBar.Plataforma')
						}
						variant="outlined"
						sx={{
							'& .MuiInputBase-input': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-input::placeholder': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loadingPlatforms ? (
										<CircularProgress
											color="inherit"
											style={{
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: selectedTheme.foreground,
											}}
											size={20}
										/>
									) : (
										params.InputProps.endAdornment
									)}
								</>
							),
						}}
					/>
				)}
			/>
			{selectedPlatform?.countries?.length > 0 ? (
				<Autocomplete
					id="country"
					data-testid="country-autocomplete"
					noOptionsText={t('FilterBar.Nenhuma opção disponível')}
					style={{
						width: 310,
						background: selectedTheme.foreground,
						borderRadius: 4,
						borderColor: 'transparent',
					}}
					isOptionEqualToValue={(option: any, value) => {
						return option.name === value.name;
					}}
					value={selectedCountry}
					disableClearable
					onChange={(_, value: any) => {
						handleSelectPlatformINcountryID(value);
					}}
					getOptionLabel={(option) => option.name}
					size="small"
					options={selectedPlatform?.countries}
					loading={loadingPlatforms}
					classes={{
						paper: classes.option,
						noOptions: classes.noOptions,
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							error={
								selectedPlatform?.countries?.length > 0 &&
								selectedCountry === null
							}
							inputProps={{
								...params.inputProps,
								autoComplete: 'new-password', // disable autocomplete and autofill
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorHigh
											: '',
								},
							}}
							placeholder={t('FilterBar.Selecione um país')}
							variant="outlined"
							sx={{
								'& .MuiInputBase-input': {
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								},
								'& .MuiInputBase-input::placeholder': {
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								},
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								'.MuiSvgIcon-root ': {
									fill:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{loadingPlatforms ? (
											<CircularProgress color="inherit" size={20} />
										) : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
				/>
			) : null}
		</>
	);

	const renderDateFilters = () => (
		<>
			<FormDivider
				name={t('FilterBar.Período')}
				margin="8px 0 6px 0"
				opacity={0.8}
				background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
				color={selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''}
			/>
			<OfferToolTip title={t('FilterBar.Pressione ENTER para enviar')}>
				<Line
					style={{
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<TextField
						id="start-date"
						data-testid="start-date"
						variant="outlined"
						label={t('FilterBar.De')}
						value={filters.selectedStartDate || ''}
						size="small"
						type="date"
						error={!!(filters.selectedFinalDate && !filters.selectedStartDate)}
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								if (filters.selectedStartDate && filters.selectedFinalDate) {
									sendSearchQuery(filters);
								}
							}
						}}
						onChange={(event) =>
							setFilters((prev) => ({
								...prev,
								selectedStartDate: event.target.value,
							}))
						}
						InputLabelProps={{
							shrink: true,
							style: { opacity: 0.7 },
						}}
						sx={{
							width: '48%',
							'& input[type="date"]::-webkit-calendar-picker-indicator': {
								filter: selectedTheme.id === 'dark' ? 'invert(0.7)' : 'none',
							},
							'& .MuiInputBase-input': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						}}
					/>
					<TextField
						id="end-date"
						data-testid="end-date"
						variant="outlined"
						label={t('FilterBar.Até')}
						value={filters.selectedFinalDate || ''}
						size="small"
						type="date"
						error={!!(filters.selectedStartDate && !filters.selectedFinalDate)}
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								if (filters.selectedStartDate && filters.selectedFinalDate) {
									sendSearchQuery(filters);
								}
							}
						}}
						onChange={(event) =>
							setFilters((prev) => ({
								...prev,
								selectedFinalDate: event.target.value,
							}))
						}
						InputLabelProps={{
							shrink: true,
							style: { opacity: 0.7 },
						}}
						sx={{
							width: '48%',
							'& input[type="date"]::-webkit-calendar-picker-indicator': {
								filter: selectedTheme.id === 'dark' ? 'invert(0.7)' : 'none',
							},
							'& .MuiInputBase-input': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						}}
					/>
				</Line>
			</OfferToolTip>
		</>
	);

	const renderClearFiltersButton = () => (
		<>
			<FormDivider name={''} margin="8px 0 3px 0" opacity={0.8} />
			<Line
				style={{
					width: '100%',
					justifyContent: 'flex-end',
				}}
			>
				{loading ? (
					<div style={{ marginRight: 25, marginTop: -5 }}>
						<LoadingDots width={55} />
					</div>
				) : (
					<Button
						variant="text"
						data-testid="clear-filters-button"
						size="small"
						onClick={handleClearFilters}
						sx={{
							marginTop: '-10px',
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						{t('FilterBar.Limpar Filtros')}
					</Button>
				)}
			</Line>
		</>
	);

	return (
		<>
			<Slide
				in={isFilterBarOpen ? true : false}
				direction="right"
				timeout={500}
			>
				<Column
					onBlur={handleCloseOnBlur}
					onMouseOver={() => setMouseOver(true)}
					onMouseLeave={() => setMouseOver(false)}
					style={{
						width: 360,
						transition: 'transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						rowGap: 10,
						padding: '10px 25px',
						left:
							vertMenuState.menuState === false ? drawerWidth : drawerWidthOpen,
						top: 50,
						borderRadius: '0 6px 6px 0',
						border: '1px solid',
						borderColor:
							selectedTheme.id === 'dark'
								? selectedTheme.foreground
								: '#e9e9e9',
						background:
							selectedTheme.id === 'dark'
								? '#303030'
								: selectedTheme.background,
						filter: 'brightness(1.03)',
						boxShadow: 'rgba(0, 0, 0, 0.2) 3px -2px 9px 1px',
						position: 'absolute',
						zIndex: 19,
					}}
				>
					{renderFilterBarHeader()}
					{renderPublicationFilters()}
					{renderDateFilters()}
					{renderSearcTermsFilters()}
					{renderPlatformFilters()}
					{renderClearFiltersButton()}
				</Column>
			</Slide>
		</>
	);
};

export default SearchFilterBar;
