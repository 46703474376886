import { ErrorResponse, apiResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const getClients = async () => {
	try {
		const response = await api.get('/clients');
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const updateClientData = async (clientINcountryID, payload) => {
	try {
		const response = await api.put(
			`/clientINcountry/${clientINcountryID}`,
			payload
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const updateClientForm = async (clientINcountryID) => {
	try {
		const response = await api.put(`/clientINcountry/${clientINcountryID}`);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const createNewClient = async (clientPayload) => {
	try {
		const response = await api.post(
			`/clients?name=${clientPayload.name}&description=${clientPayload.description}&BIID=${clientPayload.BIID}&parent=${clientPayload.parent}&ISactive=true`
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const createNewClientBranch = async (branchPayload) => {
	try {
		const response = await api.post(`/clientINcountry`, branchPayload);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const updateClientInfo = async (clientID, clientPayload) => {
	try {
		const response = await api.patch(
			`/clients/${clientID}?name=${clientPayload.name}&description=${clientPayload.description}&parent=${clientPayload.parent}&BIID=${clientPayload.BIID}`
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getClientData = async (
	clientID: string
): Promise<apiResponse | undefined> => {
	try {
		const response = await api.get(`/clientINcountry/clients/${clientID}`);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse) {
				return {
					success: false,
					status: errorResponse.status,
					message:
						errorResponse.data?.detail || 'Erro ao buscar os dados do cliente.',
				};
			}
		}
		return {
			success: false,
			status: 500,
			message:
				'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
		};
	}
};

export const deactivateClient = async (clientID) => {
	try {
		const response = await api.delete(`/clients/${clientID}`);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const updateClientStatus = async (clientID, payload) => {
	try {
		const response = await api.patch(
			`/clients/${clientID}?ISactive=${payload.ISactive}`
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const updateClientUnit = async (clientINcountryID, payload) => {
	try {
		const response = await api.put(
			`/clientINcountry/${clientINcountryID}`,
			payload
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
