import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	Zoom,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Create, CloseRounded } from '@mui/icons-material/';
import { api, setupRequestToken } from '../../../../utils/api';
import { Line } from '../../../../styles';
import { MessageType } from '../../../../interfaces';
import { OfferToolTip } from '../../../../helpers';
import { useAuth0 } from '../../../../react-auth0-spa';
import { usePO } from '../../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../components/LoadingDots';
import SelectClientModal from '../../../../components/SelectClientModal';
import { enqueueSnackbar } from 'notistack';

interface Props {
	reloadTable: any;
	rowData?: any;
}

const EditDenouncementModal: React.FC<Props> = ({
	reloadTable,
	rowData,
}: Props) => {
	const { token } = useAuth0();
	const { t } = useTranslation();
	const {
		selectedTheme,
		cursorMode,
		classificationTypes,
		selectedClient,
		userData,
	} = usePO();
	setupRequestToken(api, token);

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [denounceObs, setDenounceObs] = useState(rowData?.observation);
	const [denounceValue, setDenounceValue] = useState(
		rowData?.classificationType
	);

	const useStyles = makeStyles(() => ({
		dialogPaper: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			'& .MuiSvgIcon-root': {
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			},
		},
		renderDenounceStyles: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			borderBottom: `1px solid ${
				selectedTheme.id === 'dark'
					? selectedTheme.textColorHigh
					: selectedTheme.border
			}`,
			'& .MuiSvgIcon-root': {
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			},
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
				borderBottom: `1px solid ${
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.border
				}`,
			},
		},
		renderDenounceReason: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
		},
	}));

	const classes = useStyles();

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			setDenounceValue('NOT_SET');
			setDenounceObs('');
		}, 300);
	};

	const handleDenounceReasonChange = (event: any) => {
		setDenounceValue(event.target.value);
	};

	const handleObservationChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setDenounceObs(event.target.value);
	};

	const handleSnackbarMessage = (message: string, type: MessageType) => {
		enqueueSnackbar(message, { variant: type });
	};

	const handleEditDenoucement = async () => {
		const payload = {
			offerClassificationID: [rowData.id],
			classificationTypeID: denounceValue,
			denounceStatusID: 'CLASSIFICADA',
			clientID: selectedClient.id,
			observation: denounceObs,
		};
		try {
			setLoading(true);
			const EditSearchResponse = await api.patch(
				'/offerClassification',
				payload
			);
			setLoading(false);
			handleSnackbarMessage(EditSearchResponse.data[0].detail, 'success');
			handleClose();
			setTimeout(() => {
				reloadTable();
			}, 500);
		} catch (error) {
			setLoading(false);
			handleSnackbarMessage(
				t('Erro ao editar a denúncia. Tente novamente mais tarde.'),
				'error'
			);
			handleClose();
		}
	};

	/** Checks if user meets conditions to edit the item */
	const selectDisable = () => {
		if (
			userData.roles?.indexOf('Desenvolvedor') !== -1 ||
			userData.roles?.indexOf('Administrador da ferramenta') !== -1 ||
			userData.roles?.indexOf('Administrador do cliente') !== -1 ||
			userData.roles?.indexOf('Analista do cliente') !== -1
		) {
			return false;
		}
		if (rowData?.classificationTypeID === 'SUSPT') {
			return false;
		}
		if (rowData?.denouncerEmail === userData.email) {
			return false;
		}
		return true;
	};

	const CustomMessageSelectClientModal = () => (
		<>
			<Typography>
				{t(
					'Notificações.Tabs.Classificadas.Escolha um cliente para editar a denúncia'
				)}
				:
			</Typography>
		</>
	);

	const renderDenounceReasons = () => (
		<FormControl
			style={{
				marginTop: 5,
				width: '100%',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			}}
		>
			<InputLabel
				style={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					marginLeft: -15,
				}}
			>
				{t('Notificações.Tabs.Classificadas.Escolha um motivo')}
			</InputLabel>
			<Select
				variant="standard"
				classes={{
					select: classes.renderDenounceStyles,
					disabled: classes.renderDenounceStyles,
				}}
				MenuProps={{
					MenuListProps: {
						disablePadding: true,
					},
				}}
				value={denounceValue}
				onChange={handleDenounceReasonChange}
				style={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			>
				{classificationTypes.map((item: any) => {
					if (item.ISactive && !item.ISgood) {
						return (
							<MenuItem
								key={item.name}
								value={item.id}
								sx={{
									background:
										selectedTheme.id === 'dark' && selectedTheme.overlay3dp,
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
									},
									'&.Mui-selected': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' && selectedTheme.overlay3dp,
										},
									},
								}}
							>
								{t(`ClassificationType.${item.id}`)}
							</MenuItem>
						);
					}
					return null;
				})}
			</Select>
			{denounceValue !== 'NOT_SET' ? (
				<Line style={{ height: 100 }}>
					<Typography
						variant="subtitle2"
						style={{
							opacity: 0.8,
							margin: '20px 0 -10px 10px',
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					>
						{classificationTypes.map((item) =>
							item.id === denounceValue
								? t(`ClassificationTypeDescription.${item.description}`)
								: null
						)}
					</Typography>
				</Line>
			) : (
				<Line style={{ height: 100 }} />
			)}
			<TextField
				id="observation"
				name="observation"
				label={t('Notificações.Tabs.Classificadas.Observações')}
				value={denounceObs}
				onChange={handleObservationChange}
				multiline
				rows={2}
				variant="outlined"
				inputProps={{
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
					},
				}}
				sx={{
					'& .MuiFormLabel-root': {
						color:
							selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
					},
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
					'.MuiSvgIcon-root ': {
						fill: selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
					},
					background: selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
				}}
			/>
		</FormControl>
	);

	const renderEditIcon = () => (
		<>
			<OfferToolTip
				title={`${t('Notificações.Tabs.Classificadas.Editar Denúncia')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<span>
					<IconButton
						onClick={() => setOpen(true)}
						style={{ marginRight: -3 }}
						disabled={selectDisable()}
					>
						<Create
							style={{
								color: !selectDisable()
									? selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: '#4F4F4F'
									: selectedTheme.id === 'dark'
										? '#4F4F4F'
										: 'lightgray',
								fontSize: 24,
							}}
						/>
					</IconButton>
				</span>
			</OfferToolTip>
		</>
	);

	const renderHeader = () => (
		<>
			<DialogTitle
				style={{
					height: 60,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					color: '#FFFFFF',
					display: 'flex',
				}}
				id="max-width-dialog-title"
			>
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					<Typography
						noWrap
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primary,
							fontSize: 20,
							fontWeight: 'bold',
							maxWidth: '90%',
						}}
					>
						{t('Notificações.Tabs.Classificadas.Editar Denúncia')}
					</Typography>
					<IconButton
						onClick={handleClose}
						style={{
							marginRight: '-16px',
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
						disabled={loading}
					>
						<CloseRounded
							style={{
								color:
									selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
							}}
						/>
					</IconButton>
				</Line>
			</DialogTitle>
		</>
	);

	const renderFooter = () => (
		<Line style={{ justifyContent: 'space-between' }}>
			<Button
				size="small"
				onClick={handleClose}
				disabled={loading}
				style={{ color: loading ? 'gray' : selectedTheme.error }}
			>
				{t('Notificações.Tabs.Classificadas.Cancelar')}
			</Button>
			{loading ? (
				<LoadingDots height={30} width={70} loop />
			) : (
				<Button
					size="small"
					style={{
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.primaryDark,
						background:
							selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: selectedTheme.foreground
						}`,
					}}
					onClick={handleEditDenoucement}
				>
					{t('Notificações.Tabs.Classificadas.Salvar')}
				</Button>
			)}
		</Line>
	);

	return (
		<div>
			{renderEditIcon()}
			{selectedClient !== null && selectedClient !== undefined ? (
				<Dialog
					open={open}
					aria-label="confirm-edit-dialog"
					fullWidth
					maxWidth="md"
					disableEscapeKeyDown
					scroll="body"
					style={{ cursor: cursorMode }}
					classes={{ paper: classes.dialogPaper }}
				>
					{renderHeader()}
					<DialogContent style={{ padding: '20px 30px' }}>
						{renderDenounceReasons()}
					</DialogContent>
					<DialogActions
						style={{
							borderTop: 'solid',
							borderTopWidth: 1,
							borderTopColor:
								selectedTheme.id === 'dark'
									? selectedTheme?.footerLine
									: '#eaeaea',
							padding: 10,
							background:
								selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
						}}
					>
						{renderFooter()}
					</DialogActions>
				</Dialog>
			) : (
				<SelectClientModal
					open={open}
					close={handleClose}
					customTextComponent={CustomMessageSelectClientModal()}
				/>
			)}
		</div>
	);
};

export default EditDenouncementModal;
