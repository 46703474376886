import React, { useEffect, useState } from 'react';
import {
	IconButton,
	TextField,
	Typography,
	Zoom,
	Autocomplete,
} from '@mui/material';
import { EditRounded, CheckRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { api, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';
import { OfferToolTip } from '../../../../helpers';
import { Line } from '../../../../styles';
import { RoleTypes } from '../../../../interfaces';
import { usePO } from '../../../../utils/POContext';
import BackdropLoading from '../../../../components/BackdropLoading';
import { commitNewRole, deleteUserRole } from '../../../../services/users';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';

interface Props {
	rowData: any;
	availableRoles: any;
	initialUserRole: RoleTypes;
	initialRoleDescription: string;
	handleReload: () => void;
}

const UserRoleSelect: React.FC<Props> = ({
	rowData,
	availableRoles,
	initialUserRole,
	initialRoleDescription,
	handleReload,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const { token } = useAuth0();
	setupRequestToken(api, token);

	const [loading, setLoading] = useState(false);
	const [userRole, setUserRole] = useState<RoleTypes>(initialUserRole);
	const [readOnly, setReadOnly] = useState(false);
	const [hover, setHover] = useState(false);

	const useStyles = makeStyles(() => ({
		option: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme?.textColorHigh : '',
		},
		paper: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
		},
	}));

	useEffect(() => {
		if (rowData) {
			setUserRole(initialUserRole);
		}
	}, [rowData]);

	const classes = useStyles();

	/** Filter excessive roles if user has more than one (temporary) */
	const changeUserRole = async () => {
		setLoading(true);
		if (rowData.roles.length > 0) {
			for (const excessiveRole of rowData.roles) {
				if (excessiveRole !== userRole?.name) {
					const roleElement = availableRoles.find(
						(role) => role.name === excessiveRole
					);
					if (roleElement) {
						const response = await deleteUserRole(rowData, roleElement);
						if (response && response.status) {
							enqueueSnackbar(
								t('Usuários.Papel removido do usuário com sucesso'),
								{ variant: 'warning' }
							);
						}
					}
				}
			}
		}

		const response = await commitNewRole(rowData, userRole);
		if (response && response.status) {
			enqueueSnackbar(t('Usuários.Papel adicionado ao usuário com sucesso'), {
				variant: 'success',
			});
		} else {
			enqueueSnackbar(t(response && response.data.detail), {
				variant: 'error',
			});
		}
		setLoading(false);
		handleReload();
	};

	const handleKeyPress = (event) => {
		if (event.keyCode === 13) {
			changeUserRole();
		}
		if (event.keyCode === 27) {
			setReadOnly(false);
		}
	};

	// TODO Role change submit button (CheckIcon) to auto-submit on role select

	return (
		<>
			{loading && <BackdropLoading smallBackdrop />}
			{readOnly ? (
				<Autocomplete
					id="role-select"
					style={{ width: '100%' }}
					options={availableRoles as RoleTypes[]}
					onChange={(event, newValue) => {
						setUserRole(newValue);
					}}
					classes={{
						option: classes.option,
						paper: classes.paper,
					}}
					value={userRole}
					autoHighlight
					autoComplete
					autoSelect
					selectOnFocus
					disableClearable
					getOptionLabel={(option: any) => t(`Usuários.${option.name}`)}
					renderInput={(params) => (
						<Line
							style={{
								justifyContent: 'space-around',
								paddingLeft: 10,
							}}
						>
							<TextField
								variant="standard"
								{...params}
								onKeyDown={handleKeyPress}
								size="small"
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password',
									style: {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorMedium
												: '',
									},
								}}
								sx={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorMedium
											: '',

									'& .MuiInput-underline': {
										':after': {
											borderBottomColor:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorMedium,
										},
										':before': {
											borderBottomColor:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorMedium,
										},
									},
									'& .MuiInputBase-root': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorMedium
												: '',
									},
								}}
							/>
							<IconButton
								data-testid="confirmedChangeRole"
								onClick={() => {
									changeUserRole();
									setReadOnly(false);
								}}
							>
								<CheckRounded
									style={{
										fontSize: 16,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									}}
								/>
							</IconButton>
						</Line>
					)}
				/>
			) : (
				<div>
					{userRole ? (
						<OfferToolTip
							title={t(`Usuários.${initialRoleDescription}`)}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							arrow
							TransitionComponent={Zoom}
						>
							<Line
								onMouseOver={() => setHover(true)}
								onMouseOut={() => setHover(false)}
								onFocus={() => setHover(true)}
								onBlur={() => setHover(false)}
								style={{ justifyContent: 'center' }}
							>
								<Typography
									style={{
										paddingLeft: 25,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorMedium
												: '',
									}}
									noWrap
									align="left"
								>
									{t(`Usuários.${userRole?.name}`)}
								</Typography>
								<IconButton
									data-testid="testIcon"
									onClick={() => {
										setReadOnly(!readOnly);
									}}
									style={hover ? { opacity: 1 } : { opacity: 0 }}
								>
									<EditRounded
										data-testid="openAutocomplete"
										style={{
											fontSize: 16,
											marginTop: -2,
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										}}
									/>
								</IconButton>
							</Line>
						</OfferToolTip>
					) : null}
				</div>
			)}
		</>
	);
};
export default UserRoleSelect;
