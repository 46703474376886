import React, { useState } from 'react';
import { Collapse, Divider, IconButton, Typography } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material/';
import {
	PanelBar,
	PanelBarTitle,
	PanelBarRight,
	PanelContent,
	RegularPanel,
} from './styles';
import { usePO } from '../../utils/POContext';

interface Props {
	title: string;
	content?: any;
	open?: boolean;
	unmountOnClose?: boolean;
	children?: React.ReactNode;
	width?: number;
	preOpen?: boolean;
	noMargin?: boolean;
	disabled?: boolean;
	contentPadding?: string;
	panelKey?: string;
}

const Panel: React.FC<Props> = ({
	title,
	content,
	open,
	unmountOnClose,
	children,
	width,
	preOpen,
	noMargin,
	disabled,
	contentPadding,
	panelKey,
}: Props) => {
	const { selectedTheme } = usePO();
	const [expanded, setExpanded] = useState(!!preOpen);
	const [alreadyExpanded, setAlreadyExpanded] = useState(false);
	const [iconExpanded, setIconExpanded] = useState(<ExpandMore />);

	if (open === true && alreadyExpanded === false) {
		setExpanded(true);
		setAlreadyExpanded(true);
	}

	if (open === false && alreadyExpanded === true) {
		setExpanded(false);
		setAlreadyExpanded(false);
	}

	const handleExpandClick = () => {
		setExpanded(!expanded);
		if (expanded === false) {
			setIconExpanded(<ExpandLess />);
		}
		if (expanded === true) {
			setIconExpanded(<ExpandMore />);
		}
	};

	return (
		<RegularPanel
			key={panelKey}
			style={noMargin ? { marginBottom: 0 } : { marginBottom: 5 }}
		>
			<PanelBar
				onClick={handleExpandClick}
				style={{
					width: width || '100%',
					pointerEvents: disabled ? 'none' : 'all',
					opacity: disabled ? 0.4 : 1,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay3dp
							: selectedTheme.foreground,
				}}
			>
				<PanelBarTitle>
					<Typography
						variant="subtitle2"
						noWrap
						style={{
							width: '90%',
							fontWeight: 'bold',
							opacity: 0.8,
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{title}
					</Typography>
				</PanelBarTitle>
				<PanelBarRight>
					<IconButton
						size="small"
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
						sx={{
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						{iconExpanded}
					</IconButton>
				</PanelBarRight>
			</PanelBar>

			<Collapse
				in={expanded}
				timeout="auto"
				unmountOnExit={unmountOnClose || false}
			>
				<PanelContent
					style={{
						padding: contentPadding || '20px 30px',
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay6dp
								: selectedTheme.foreground,
						boxShadow:
							selectedTheme.id === 'dark'
								? '0px 0px 5px rgba(0, 0, 0, 0.5)'
								: '0px 0px 6px rgba(0, 0, 0, 0.1)',
						borderRadius: 4,
					}}
				>
					{content || children}
				</PanelContent>
				<Divider
					sx={{
						background: selectedTheme.id === 'dark' && selectedTheme.footerLine,
						opacity: 0.5,
					}}
				/>
			</Collapse>
		</RegularPanel>
	);
};

export default Panel;
