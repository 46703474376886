import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogActions,
	IconButton,
	Typography,
	Zoom,
	TextField,
	Button,
} from '@mui/material';
import { AddCircleRounded, CloseRounded, Edit } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import { t } from 'i18next';
import { Column, Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import { OfferToolTip } from '../../../helpers';
import LoadingDots from '../../../components/LoadingDots';
import { makeStyles } from '@mui/styles';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';

interface Props {
	isEditing: boolean;
	rowData: any;
	onSubmit: (newCategory: {
		title: string;
		description: string;
		parent?: string;
	}) => void;
}

const AddCategoriesModal: React.FC<Props> = ({
	isEditing,
	rowData,
	onSubmit,
}: Props) => {
	const { selectedTheme } = usePO();
	const [open, setOpen] = useState(false);
	const [loading] = useState(false);

	const initialCategories = [
		{
			id: '1',
			name: 'Cachorro',
			description: 'categoria de cachorros',
			route: [
				{ id: '1', name: 'Coleira' },
				{ id: '2', name: 'Ração' },
				{ id: '4', name: 'Bebedouro' },
			],
		},
		{
			id: '2',
			name: 'Tênis',
			description: 'categoria de Tênis',
			route: [],
		},
		{
			id: '3',
			name: 'Roupas e acessórios',
			description: 'categoria de roupas e acessórios',
			route: [
				{ id: '6', name: 'Camiseta' },
				{ id: '7', name: 'Calça' },
			],
		},
	];

	const getAllCategoryIds = (categories) => {
		const allIds: string[] = [];
		categories.forEach((category) => {
			allIds.push(category.id);
			if (category.children) {
				category.children.forEach((child) => allIds.push(child.id));
			}
		});
		return allIds;
	};

	const [expandedItems] = React.useState<string[]>(
		getAllCategoryIds(initialCategories)
	);
	const [selectedItem, setSelectedItem] = React.useState<any>(null);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');

	const handleChange = (event: any, newValue: any) => {
		setSelectedItem(newValue);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedItem(null);
	};

	useEffect(() => {
		if (rowData) {
			setTitle(rowData.title || '');
			setDescription(rowData.description || '');

			if (rowData.route && rowData.route.length > 0) {
				const mainCategory = initialCategories.find(
					(cat) => cat.id === rowData.id
				);
				setSelectedItem(mainCategory || null);
			} else {
				setSelectedItem(null);
			}
		} else {
			setTitle('');
			setDescription('');
			setSelectedItem(null);
		}
	}, [isEditing, rowData]);

	const handleOpen = () => {
		setOpen(true);
	};

	const useStyles = makeStyles(() => ({
		autoCompleteStyle: {
			maxHeight: 200,
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		noOptions: {
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
		},
	}));

	const classes = useStyles();

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const texticonStyleHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleSubmit = () => {
		const newCategory = {
			title: title,
			formattedRoute: selectedItem.route || [],
			description: description,
			parent: selectedItem?.name || '',
		};
		onSubmit(newCategory);
		handleClose();
	};

	const renderHeader = () => (
		<DialogTitle
			style={{
				height: 60,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.tableHead
						: selectedTheme.gradient,
				color: '#FFFFFF',
				display: 'flex',
			}}
			id="max-width-dialog-title"
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Typography
					noWrap
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						fontSize: 20,
						fontWeight: 'bold',
						maxWidth: '90%',
					}}
				>
					{isEditing
						? t('Categorias.Editar categoria')
						: t('Categorias.Adicionar Categoria')}
				</Typography>
				<IconButton onClick={handleClose} style={{ marginRight: '-16px' }}>
					<CloseRounded
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderEditIcon = () => (
		<OfferToolTip
			title={t('Categorias.Editar categoria')}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				style={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
				onClick={handleOpen}
			>
				<Edit />
			</IconButton>
		</OfferToolTip>
	);

	const renderCustomHeaderComponent = () => (
		<OfferToolTip
			title={t('Categorias.Adicionar Categoria')}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				style={{ marginRight: '-15px', borderRadius: 5 }}
				onClick={() => setOpen(true)}
			>
				<Typography
					variant="subtitle1"
					style={{
						fontWeight: 'bold',
						color: texticonStyleHeader(),
						marginRight: 15,
						marginTop: 2,
					}}
				>
					{t('Categorias.Adicionar Categoria')}
				</Typography>
				<AddCircleRounded
					style={{
						color: texticonStyleHeader(),
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const renderContent = () => (
		<DialogActions
			style={{
				padding: '30px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			}}
		>
			<Column
				style={{
					height: '100%',
					alignItems: 'start',
					rowGap: 10,
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
				}}
			>
				<Typography
					style={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					}}
				>
					{t('Categorias.Nome')}:
				</Typography>
				<Line
					style={{
						overflow: 'hidden',
						alignItems: 'start',
					}}
				>
					<TextField
						id="title"
						variant="outlined"
						name="title"
						value={title}
						onChange={(event) => {
							setTitle(event.target.value);
						}}
						fullWidth
						margin="dense"
						size="small"
						InputLabelProps={{
							shrink: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						sx={{
							width: '100%',
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						inputProps={{
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							},
						}}
					/>
				</Line>
				<Typography
					style={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					}}
				>
					{t('Categorias.Vincular categoria')}:
				</Typography>
				<Line
					style={{
						overflow: 'hidden',
						alignItems: 'start',
					}}
				>
					<Autocomplete
						id="category-select"
						noOptionsText={t('Categorias.Nenhuma opção disponível')}
						options={initialCategories}
						getOptionLabel={(option) => option.name}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						onChange={handleChange}
						value={selectedItem}
						classes={{
							option: classes.autoCompleteStyle,
							paper: classes.autoCompleteStyle,
							noOptions: classes.noOptions,
						}}
						filterOptions={(options, { inputValue }) => {
							return options.filter((category) => {
								if (
									category.name.toLowerCase().includes(inputValue.toLowerCase())
								) {
									return true;
								}
								return (
									category.route &&
									category.route.some((child) =>
										child.name.toLowerCase().includes(inputValue.toLowerCase())
									)
								);
							});
						}}
						renderOption={(props, option) => (
							<li {...props}>
								<SimpleTreeView expandedItems={expandedItems}>
									<TreeItem
										key={option.id}
										label={option.name}
										itemId={option.id}
										onClick={() => handleChange(null, option)}
									>
										{option.route &&
											option.route.map((child) => (
												<TreeItem
													key={child.id}
													label={child.name}
													itemId={`${option.id}-${child.id}`}
													onClick={(event) => {
														event.stopPropagation();
														handleChange(event, child);
													}}
												/>
											))}
									</TreeItem>
								</SimpleTreeView>
							</li>
						)}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								size="small"
								placeholder={t('Categorias.Pesquisar categoria')}
								inputProps={{
									...params.inputProps,
									style: {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: '',
									},
								}}
								sx={{
									'& .MuiAutocomplete-paper': {
										maxHeight: 100,
										overflowY: 'auto',
									},
									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
						)}
						fullWidth
					/>
				</Line>
				<Typography
					style={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					}}
				>
					{t('Categorias.Descrição')}:
				</Typography>
				<TextField
					variant="outlined"
					name="description"
					value={description}
					onChange={(event) => {
						setDescription(event.target.value);
					}}
					fullWidth
					multiline
					rows={6}
					margin="dense"
					size="small"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					sx={{
						width: '100%',
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					}}
					inputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						},
					}}
				/>
			</Column>
		</DialogActions>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Categorias.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						type="button"
						size="small"
						onClick={handleSubmit}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: handleBorderButton(),
						}}
					>
						{t('Categorias.Salvar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<>
			{isEditing ? renderEditIcon() : renderCustomHeaderComponent()}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-label="confirm-edit-dialog"
				fullWidth
				maxWidth="md"
				scroll="body"
			>
				{renderHeader()}
				{renderContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default AddCategoriesModal;
